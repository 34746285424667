(function () {
  angular.module('kmi.lms.core').component('trainPoliciesComponent', {
    template: require('ajs/custom_modules/train/app/policies.html').default,
    controller: TrainPoliciesController,
    controllerAs: 'vm',
  });

  function TrainPoliciesController($scope, $sce, policiesService, $timeout, $uiRouterGlobals, backUrlService) {
    var vm = this;

    vm.backUrl = backUrlService;

    policiesService.getPolicies().then(function (policies) {
      vm.policies = policies;
      vm.policies.text = $sce.trustAsHtml(policies.text);

      if ($uiRouterGlobals.params.scrollTo) {
        $timeout(function () {
          $scope.$broadcast('event:scrollTo', $uiRouterGlobals.params.scrollTo);
        });
      }
    });
  }
})();
