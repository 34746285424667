(function () {
  angular.module('kmi.lms.course.details').component('searchCourseCeDates', {
    template: require('ajs/custom_modules/train/course/components/ce-dates/ce-dates-label.html').default,
    controllerAs: 'vm',
    controller: TrainSearchCourseCeDatesCtrl,
    bindings: {
      course: '<',
    },
  });

  /* @ngInject */
  function TrainSearchCourseCeDatesCtrl($window) {
    var vm = this;

    vm.serverTimeZone = $window.elmsEnvironment.serverTimeZone;
  }
})();
