(function () {
  angular.module('kmi.lms.quiz').config(questionModuleConfig);

  function questionModuleConfig($provide) {
    $provide.decorator('quizQuestionEnums', function ($delegate) {
      $delegate.typeExtensionTemplates = [
        {
          extensionId: $delegate.questionTypeExtension.trueFalse.id,
          answerVariants: [
            { text: 'True', correct: true },
            { text: 'False', correct: false },
          ],
        },
        {
          extensionId: $delegate.questionTypeExtension.yesNo.id,
          answerVariants: [
            {
              text: 'Yes',
              correct: true,
            },
            {
              text: 'No',
              correct: false,
            },
          ],
        },
        {
          extensionId: $delegate.questionTypeExtension.likertScale.id,
          answerVariants: [
            { text: 'Strongly Agree', correct: true },
            { text: 'Agree', correct: true },
            { text: 'Neutral, don’t agree or disagree', correct: true },
            { text: 'Disagree', correct: true },
            { text: 'Strongly Disagree', correct: true },
          ],
        },
      ];

      $delegate.availableQuestionTypes = Object.freeze({
        //preAssessment:
        1: [
          $delegate.questionType.multiChoice,
          $delegate.questionType.checkAllThatApply,
          $delegate.questionType.shortAnswer,
          $delegate.questionType.matching,
          $delegate.questionType.fillin,
        ],
        //assessment:
        2: [
          $delegate.questionType.multiChoice,
          $delegate.questionType.checkAllThatApply,
          $delegate.questionType.shortAnswer,
          $delegate.questionType.matching,
          $delegate.questionType.fillin,
        ],
        // evaluation:
        3: [
          $delegate.questionType.multiChoice,
          $delegate.questionType.checkAllThatApply,
          $delegate.questionType.shortAnswer,
          $delegate.questionType.matching,
          $delegate.questionType.fillin,
        ],
        // exam:
        4: [
          $delegate.questionType.multiChoice,
          $delegate.questionType.checkAllThatApply,
          $delegate.questionType.shortAnswer,
          $delegate.questionType.matching,
          $delegate.questionType.fillin,
        ],
        // survey:
        5: [
          $delegate.questionType.multiChoice,
          $delegate.questionType.checkAllThatApply,
          $delegate.questionType.shortAnswer,
          $delegate.questionType.fillin,
        ],
      });

      $delegate.availableQuestionTypeExtensions = Object.freeze({
        //preAssessment:
        1: [
          $delegate.questionTypeExtension.trueFalse,
          $delegate.questionTypeExtension.yesNo,
          $delegate.questionTypeExtension.likertScale,
        ],
        //assessment:
        2: [
          $delegate.questionTypeExtension.trueFalse,
          $delegate.questionTypeExtension.yesNo,
          $delegate.questionTypeExtension.likertScale,
        ],
        // evaluation:
        3: [
          $delegate.questionTypeExtension.trueFalse,
          $delegate.questionTypeExtension.yesNo,
          $delegate.questionTypeExtension.likertScale,
        ],
        // exam:
        4: [
          $delegate.questionTypeExtension.trueFalse,
          $delegate.questionTypeExtension.yesNo,
          $delegate.questionTypeExtension.likertScale,
        ],
        // survey:
        5: [
          $delegate.questionTypeExtension.trueFalse,
          $delegate.questionTypeExtension.yesNo,
          $delegate.questionTypeExtension.likertScale,
        ],
      });

      return $delegate;
    });

    $provide.decorator('quizOptionsEnum', function ($delegate) {
      $delegate.timeTrackingAttemptsEnabled = true;
      $delegate.showTitle = true;

      return $delegate;
    });
  }
})();
