(function () {
  angular
    .module('kmi.lms.train.user.registration')
    .constant('trainRegistrationConfig', [
      { affiliate: 'cdc', byZipSelection: true, basePath: 'cdctrain' },
      { affiliate: 'tennessee', byZipSelection: { skipOriginal: true }, basePath: 'tn' },
      { affiliate: 'montana', byZipSelection: { skipOriginal: true }, basePath: 'mt' },
      { affiliate: 'fda', byZipSelection: false, basePath: 'fda' },
      { affiliate: 'connecticut', byZipSelection: false, basePath: 'connecticut' },
      { affiliate: 'delaware', byZipSelection: { skipOriginal: true }, basePath: 'detrain' },
      { affiliate: 'illinois', byZipSelection: true, basePath: 'illinois' },
      { affiliate: 'kansas', byZipSelection: true, basePath: 'kansas' },
      { affiliate: 'maryland', byZipSelection: { skipOriginal: true }, basePath: 'maryland' },
      { affiliate: 'michigan', byZipSelection: true, basePath: 'mi-train' },
      { affiliate: 'mrc', byZipSelection: true, basePath: 'mrc' },
      { affiliate: 'virginia', byZipSelection: false, basePath: 'virginia' },
      { affiliate: 'wyoming', byZipSelection: { skipOriginal: true }, basePath: 'WYOMING' },
      { affiliate: 'pennsylvania', byZipSelection: false, basePath: 'pa' },
      { affiliate: null, byZipSelection: false, basePath: 'inclusivecare' },
      { affiliate: 'south dakota', byZipSelection: { skipOriginal: true }, basePath: 'sd' },
      // {affiliate: 'massachusetts', byZipSelection: false, basePath: 'massachusetts',
      //   showAsByZipOnlyForPaths: ['main']},
      { affiliate: 'ice health service corps (ihsc)', byZipSelection: false, basePath: 'ihsctrain' },
      { affiliate: 'georgia', byZipSelection: { skipOriginal: true }, basePath: 'georgia' },
      { affiliate: 'vba mde', byZipSelection: true, basePath: 'vbamde' },
      { affiliate: 'department for hiv elimination (ga)', byZipSelection: false, basePath: 'DHE' },
      { affiliate: 'massachusetts', byZipSelection: { skipOriginal: true }, basePath: 'ma' },
      { affiliate: 'idaho', byZipSelection: { skipOriginal: true }, basePath: 'idaho' },
      { affiliate: 'california', byZipSelection: { skipOriginal: true }, basePath: 'cdphtrain' },
      {
        affiliate: 'santa clara county (ca)',
        byZipSelection: false,
        defaultGroup: true,
        skipAffiliateStep: true,
        basePath: 'sccphd',
      },
    ])
    .component('trainUserRegistrationComponent', {
      template: require('ajs/custom_modules/train/user/registration/main.html').default,
      controller: UserRegistrationController,
      controllerAs: 'vm',
    });

  /* @ngInject */
  function UserRegistrationController(
    $scope,
    $state,
    $uiRouterGlobals,
    trainRegistrationConfig,
    TrainRegistrationWizard,
    siteSettingService,
    geoService,
    notificationService,
    elmsAuthService,
    _,
    urlVersionService,
    User,
    regexPatterns,
    backUrlService,
    rootScopeService,
    groupService,
  ) {
    var vm = this,
      mainStep,
      selectedGroups = [],
      affiliateStep;
    vm.$onInit = onInit;

    vm.siteSettings = siteSettingService.getSiteSetting();
    vm.regConfig = trainRegistrationConfig;
    vm.checkedZipCode = {};
    vm.affiliate = !vm.siteSettings.default && vm.siteSettings.basePath;
    vm.affiliateConfig = _.find(vm.regConfig, { basePath: vm.affiliate });
    vm.zipPattern = regexPatterns.internationalZip;
    vm.passwordPattern = regexPatterns.password;
    vm.loginNamePattern = /^[^\s]*$/;
    vm.submitted = false;

    vm.nextStep = nextStep;
    vm.prevStep = prevStep;
    vm.showForm = showForm;
    vm.submit = submit;

    function onInit() {
      vm.user = new User({
        loginName: '',
        password: '',
        firstName: '',
        lastName: '',
        email: '',
        policiesSignOff: false,
        receiveEmails: true,
        stayInformed: true,
        annualNotification: true,
        stateByZipCode: true,
        countryByZipCode: true,
        groupByState: vm.affiliateConfig ? null : true,
        groups: [],
      });

      if (_.get(vm.affiliateConfig, 'defaultGroup')) {
        groupService.getDefaultSelfRegGroups().then((defaultSelfRegGroups) => {
          for (let i = defaultSelfRegGroups.length - 1; i >= 0; i--) {
            vm.user['groups'].push(defaultSelfRegGroups[i].group);
          }
        });
      }

      mainStep = {
        name: 'userAttributePage',
        type: 'userAttributes',
        restrictions: [
          function () {
            return !vm.userForm.$pending && vm.userForm.$valid;
          },
        ],
        resolve: [
          function () {
            return checkStateZipCode();
          },
        ],
        onEnter: function () {
          vm.user.groups = [];
          vm.user.attributes = null;
          vm.customSubmit = null;
        },
      };

      vm.registrationWizard = new TrainRegistrationWizard(mainStep);

      if (vm.affiliateConfig && vm.affiliateConfig.affiliate && !_.get(vm.affiliateConfig, 'skipAffiliateStep')) {
        affiliateStep = {
          name: vm.affiliate,
          affiliate: vm.affiliateConfig.affiliate,
          type: 'affiliateAttributes',
          restrictions: function () {
            return !vm.userFieldForm.$pending && vm.userFieldForm.$valid;
          },
          onEnter: function () {
            vm.user.groups = [];
          },
        };

        vm.registrationWizard.addStep(affiliateStep);
      }

      vm.registrationWizard.onFinish = submit;

      $scope.$on('elmsAuthService.notification.login', function () {
        if ($uiRouterGlobals.params.stepCourseProvider) {
          backUrlService.passThroughRedirect('prompt.userRegistration.course_provider');
        } else if ($uiRouterGlobals.params.stepAccountCreated) {
          $state.go('prompt.accountCreated');
        } else {
          backUrlService.goBack();
        }
      });

      $scope.$on('event:skipAttributesByZipSelection', function (e, value) {
        if (value && vm.registrationWizard.steps[2]) {
          vm.registrationWizard.removeStep(vm.registrationWizard.steps[2]);
          vm.checkedZipCode = {};
        }
      });
    }

    function checkStateZipCode() {
      if (
        (!vm.affiliateConfig || vm.affiliateConfig.byZipSelection) &&
        vm.user.zip &&
        vm.user.zip !== vm.checkedZipCode.zip
      ) {
        return geoService
          .getStateByZip(vm.user.zip, true)
          .toPromise()
          .then(function (state) {
            vm.checkedZipCode.zip = vm.user.zip;
            addRemoveByZipStep(state);

            addRemoveOriginalAffiliateStep(state);

            if (vm.registrationWizard.getStepsCount() < 2) {
              vm.user.groupByState = true;
            }
          });
      }
    }

    function addRemoveOriginalAffiliateStep(state) {
      if (!vm.affiliateConfig || !affiliateStep) {
        return;
      }

      if (
        !_.find(vm.registrationWizard.steps, { affiliate: vm.checkedZipCode.affiliate }) &&
        !_.find(vm.registrationWizard.steps, { affiliate: vm.affiliateConfig.affiliate })
      ) {
        vm.registrationWizard.addStep(affiliateStep);
      }

      if (
        vm.affiliateConfig.byZipSelection &&
        _.get(vm.affiliateConfig.byZipSelection, 'skipOriginal') &&
        affiliateStep.affiliate !== _.get(state, 'stateName', '').toLowerCase()
      ) {
        vm.registrationWizard.removeStep(affiliateStep);
      }
    }

    function addRemoveByZipStep(state) {
      var byZipStep = {},
        byZipConfig,
        stepsCount = vm.registrationWizard.getStepsCount();

      // remove previously by zip set step
      if (isLastStepByZipSelected()) {
        vm.registrationWizard.removeStep(vm.registrationWizard.steps[stepsCount - 1]);
      }

      if (state && state.id) {
        byZipConfig = _.find(vm.regConfig, function (conf) {
          var stateName = state.stateName.toLowerCase();
          return conf.affiliate === stateName || conf.basePath === stateName;
        });

        // no by zip config found or affiliate is already in steps
        if (!byZipConfig || _.includes(_.map(vm.registrationWizard.steps, 'name'), byZipConfig.basePath)) {
          return;
        }

        if (
          byZipConfig.showAsByZipOnlyForPaths &&
          !_.includes(byZipConfig.showAsByZipOnlyForPaths, vm.siteSettings.basePath)
        ) {
          return;
        }

        vm.checkedZipCode.affiliate = byZipConfig.affiliate;

        byZipStep = {
          name: byZipConfig.basePath,
          affiliate: byZipConfig.affiliate,
          type: 'affiliateAttributesByZip',
          restrictions: function () {
            return !vm.userAttributeForm.$pending && vm.userAttributeForm.$valid;
          },
          onEnter: function () {
            selectedGroups = angular.copy(vm.user.groups);
            vm.step = null;
          },
        };

        vm.registrationWizard.addStep(byZipStep);
      }
    }

    function isLastStepByZipSelected() {
      var stepsCount = vm.registrationWizard.getStepsCount();

      return (
        !!vm.checkedZipCode.affiliate &&
        vm.registrationWizard.steps[stepsCount - 1].affiliate === vm.checkedZipCode.affiliate
      );
    }

    function nextStep() {
      vm.submitted = true;

      vm.registrationWizard.nextStep().then(
        function () {},
        function () {
          notificationService.error('Please fill in all required fields', 2e3);
        },
      );
    }

    function prevStep() {
      vm.registrationWizard.prevStep();
    }

    function submit() {
      $scope.$broadcast('event:user:beforeSave', vm.user);

      urlVersionService.update();
      if (selectedGroups && selectedGroups.length) {
        vm.user.groups = _.union(vm.user.groups, selectedGroups);
      }

      if (vm.registrationWizard.getStepsCount() === 1) {
        vm.user.groupByState = vm.affiliateConfig ? vm.user.groupByState : true;
      }

      var userDraft = angular.copy(vm.user);
      var promise = userDraft.save();
      promise.then(
        function () {
          elmsAuthService.login({
            username: vm.user.loginName,
            password: vm.user.password,
          });
        },
        function (reason) {
          if (reason && reason.status === 403 && reason.data && reason.data.code === 'PASSWORD_RULES_FAILED') {
            rootScopeService.broadcast('event:passwordRulesStatusUpdated', reason.data.context);
          } else {
            notificationService.error('An error occurred during registration. Please try again later.', 5e3);
          }
        },
      );

      return promise;
    }

    /**
     * @description
     * Determine visibility of registration form via current state
     */
    function showForm() {
      return $state.is('prompt.userRegistration');
    }
  }
})();
