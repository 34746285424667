(function () {
  angular.module('kmi.lms.train.user.learning').component('trainPendingCourseSurveys', {
    template: require('ajs/custom_modules/train/user/learning/pending-course-surveys.html').default,
    controller: TrainPendingCourseSurveysController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function TrainPendingCourseSurveysController($http, currentUser, authorizationService, navigationService, $window) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      let window = angular.element($window);

      checkMobileView();
      window.bind('resize', checkMobileView);

      function checkMobileView() {
        vm.mobileView = window.width() < 768;
      }

      vm.loading = true;

      $http
        .get(['/a/user/', currentUser.get().id, '/courses/pending-surveys/'].join(''))
        .then(function (response) {
          vm.surveys = response.data;
          if (vm.surveys.length) {
            authorizationService.authorizeState('edit.account', {});
            authorizationService.authorizeState('edit.account.groups', {});
            vm.surveys.forEach(function (surveyCourse) {
              authorizationService.authorizeState('main.course.default', {
                id: surveyCourse.id + '',
              });
              authorizationService.authorizeState('main.course', {
                id: surveyCourse.id + '',
              });
              authorizationService.authorizeState('view.course_content', {
                courseId: surveyCourse.id + '',
                type: 'course',
              });
            });
          } else {
            navigationService.goHome();
          }
        })
        .finally(function () {
          vm.loading = null;
        });
    }
  }
})();
