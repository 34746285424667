(function () {
  angular.module('kmi.lms.train.home').component('trainHomeComponent', {
    template: require('ajs/custom_modules/train/home/tile-home.html').default,
    controller: TrainHomeCtrl,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function TrainHomeCtrl($scope, currentUser, courseService, moment, _) {
    $scope.todayString = moment().startOf('day').format('YYYY-MM-DD');
    $scope.currentUser = currentUser.get();

    activate();

    function activate() {
      //Get live course formats in order to build "Browse Events" search link
      courseService.getCourseOptions().then(function (options) {
        // Build IDs array
        $scope.liveCourseFormats = _.map(
          _.filter(options.formats, function (item) {
            // 2 - Live Event, 7 - Exercise
            return _.includes([2, 7], item.typeId);
          }),
          'id',
        );
      });
    }
  }
})();
