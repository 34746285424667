(function () {
  /**
   * @ngdoc controller
   * @name kmi.lms.train.user.edit.controller:TrainCheckEmsAccountController
   *
   * @description
   * Provides "my account" functionality
   */
  angular.module('kmi.lms.train.user.edit').component('trainCheckEmsAccountComponent', {
    template: require('ajs/custom_modules/train/user/edit/ems/check-ems-account.html').default,
    controller: TrainCheckEmsAccountController,
    controllerAs: 'vm',
  });

  /* @ngInject*/
  function TrainCheckEmsAccountController($http) {
    var vm = this;

    activate();

    function activate() {
      vm.loading = $http
        .get('/a/integration/ems/providers/me/')
        .then(function (response) {
          vm.emsProvider = response.data;
        })
        .finally(function () {
          vm.loading = null;
        });
    }
  }
})();
