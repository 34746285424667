(function () {
  angular.module('kmi.lms.core').component('trainPromptView', {
    controller: PromptViewController,
    controllerAs: 'vm',
    template: require('ajs/custom_modules/train/app/prompt-view.html').default,
  });

  function PromptViewController(siteSettingService) {
    var vm = this;

    vm.currentDate = new Date();
    vm.siteSettings = siteSettingService.getSiteSetting();
  }
})();
