(function () {
  angular.module('kmi.lms.course.details').component('courseDetailedInfo', {
    bindings: {
      course: '=',
      hideNoDataMessage: '=?',
    },
    template: require('ajs/custom_modules/train/course/components/detailed-info.html').default,
    controller: 'CourseDetailedInfoController',
    controllerAs: 'vm',
  });
})();
