(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.registration:virginiaAttributes
   *
   * @description
   * Virginia account creation attributes selection
   */
  angular.module('kmi.lms.train.user.registration').directive('virginiaAttributes', virginiaAttributes);

  /* @ngInject */
  function virginiaAttributes() {
    return {
      restrict: 'EA',
      controller: AttributesCtrl,
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        user: '=user',
        step: '=?step',
        affiliate: '=?affiliate',
      },
      template: require('ajs/custom_modules/train/user/registration/affiliates/components/virginia-attributes.html')
        .default,
    };
  }

  /* @ngInject */
  function AttributesCtrl(_) {
    var vm = this;

    vm.categoryIds = _.values({ VIRGINIA_ATTRIBUTES: 77, VIRGINIA_EMS_ATTRIBUTES: 150 });
    vm.nextStep = nextStep;
    vm.prevStep = prevStep;
    vm.steps = [null, 'customAttributes'];

    function nextStep() {
      var stepId = _.includes(vm.steps, vm.step) ? vm.steps.indexOf(vm.step) : 0;

      if (stepId === 0) {
        vm.steps[0] = vm.step;
      }

      stepId++;
      vm.step = vm.steps[stepId];
    }

    function prevStep() {
      var stepId = vm.steps.indexOf(vm.step);

      stepId--;
      vm.step = vm.steps[stepId];
    }
  }
})();
