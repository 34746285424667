(function () {
  angular.module('kmi.lms.train.user.learning').component('trainUserTranscriptTabComponent', {
    template: require('ajs/custom_modules/train/user/learning/transcript-tab.html').default,
    controller: TrainUserTranscriptTabController,
    controllerAs: 'vm',
  });

  /* @ngInject*/
  function TrainUserTranscriptTabController($scope, $uibModal, $location, courseRegistrationStatus, _) {
    var vm = this;
    vm.$onInit = onInit;

    vm.queryParams = {
      include_inactive_courses: true,
      query: angular.toJson({ highestStatuses: courseRegistrationStatus.terminalSet, creditsAwarded: true }),
    };

    vm.sorting = { field: 'completionDate', dir: 'desc' };

    vm.columns = {
      status: true,
      completionDate: true,
      score: true,
      creditType: true,
    };
    vm.showWithdrawn = false;

    vm.downloadTranscript = downloadTranscript;
    vm.showWithdrawnChange = showWithdrawnChange;

    function onInit() {
      vm.sorting = { field: 'completionDate', dir: 'desc' };
      vm.searchQuery = $location.search();

      if (vm.searchQuery.filter) {
        vm.sorting = { field: vm.searchQuery.filter, dir: vm.searchQuery.dir };
      }

      if (vm.searchQuery.showWithdrawn) {
        vm.showWithdrawn = true;
      }
      showWithdrawnChange();

      // Listen for the loading event from the nested views in order to disable UI controls
      $scope.$on('event:dataLoading', function (event, loading) {
        vm.dataLoading = loading;
      });
    }

    function downloadTranscript() {
      $uibModal.open({
        component: 'trainTranscriptModal',
        resolve: {
          statuses: function () {
            return vm.statusFilter
              ? _.filter(vm.statusFilter, function (n) {
                  return !isNaN(parseFloat(n)) && isFinite(n);
                })
              : courseRegistrationStatus.terminalSet;
          },
          sorting: function () {
            return vm.sorting;
          },
        },
      });
    }

    function showWithdrawnChange() {
      var searchQuery = $location.search();
      if (!vm.showWithdrawn) {
        if (searchQuery.showWithdrawn) {
          delete searchQuery.showWithdrawn;
        }
        vm.statusFilter = _.without(courseRegistrationStatus.terminalSet, courseRegistrationStatus.withdrawn);
      } else {
        angular.extend(searchQuery, { showWithdrawn: vm.showWithdrawn });
        vm.statusFilter = courseRegistrationStatus.terminalSet;
      }
      $location.search(searchQuery);
    }
  }
})();
