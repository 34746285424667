(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.registration:groupWithStateSelection
   *
   * @description
   * Group selection with addition state group selection by state zip code
   */
  angular.module('kmi.lms.train.user.registration').directive('groupWithStateSelection', groupWithStateSelection);

  /* @ngInject */
  function groupWithStateSelection() {
    return {
      restrict: 'EA',
      controller: GroupWithStateCtrl,
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        user: '=user',
        selfStep: '=?step',
        submitHandler: '&onSubmit',
        hasSubmit: '=hasSubmit',
        affiliate: '=?affiliate',
        basePath: '=?basePath',
      },
      template:
        require('ajs/custom_modules/train/user/registration/affiliates/components/group-with-state-selection.html')
          .default,
    };
  }

  /* @ngInject */
  function GroupWithStateCtrl($scope, $q, $http, $timeout, Group, portalGroups, geoService, _) {
    var vm = this,
      selectedGroups;

    vm.loading = false;
    vm.hasStateGroupsStep = true;

    vm.nextStep = nextStep;
    vm.prevStep = prevStep;
    vm.$onInit = onInit;

    function onInit() {
      vm.hasSubmit = true;
      vm.submit = vm.submitHandler;

      $scope.$watch(
        function () {
          return $http.pendingRequests.length;
        },
        function () {
          vm.stepLoading = !!$http.pendingRequests.length;
        },
      );

      activate();
    }

    function activate() {
      vm.loading = true;

      if (vm.affiliate) {
        vm.hasStateGroupsStep = true;
        vm.affiliateByZipCode = vm.affiliate;
        vm.affiliateBasePath = vm.basePath;
        vm.loading = false;
      } else {
        $q.all([getChildGroups(portalGroups.state), geoService.getStateByZip(vm.user.zip, true).toPromise()]).then(
          function (results) {
            bindGroupByState(results[0], results[1]);
            // check if state group has child groups
            getChildGroups(vm.groupByState[0].id).then(function (groups) {
              if (!(groups.items && groups.items.length)) {
                vm.hasStateGroupsStep = false; // if has no - skip state group selection
              }
              vm.loading = false;
              vm.cdcGroupSelectionForm.$setValidity('groups', true);
            });
          },
        );

        $timeout(function () {
          vm.cdcGroupSelectionForm.$setValidity('groups', false);
        });
      }

      $scope.$emit('event:skipAttributesByZipSelection', true);

      $scope.$watch('vm.stateGroupSelectionForm.$valid', updateUserGroups);

      $scope.$on('$destroy', function () {
        vm.hasSubmit = false;
      });

      vm.steps = [null, 'stateGroups'];
    }

    function updateUserGroups() {
      if (
        !vm.hasStateGroupsStep ||
        (vm.stateGroupSelectionForm && vm.stateGroupSelectionForm.$valid && vm.user && vm.user.groups)
      ) {
        vm.user.groups = _.union(vm.user.groups, selectedGroups);
      }
    }

    function nextStep() {
      selectedGroups = vm.user.groups;

      if (vm.hasStateGroupsStep) {
        if (vm.selfStep) {
          vm.steps[0] = vm.selfStep;
        }

        vm.selfStep = vm.steps[1];

        $timeout(function () {
          vm.hasSubmit = vm.customSubmit; //check if child directive has custom submit
        }, 100);
      } else {
        vm.user.groups = vm.groupByState;
        updateUserGroups();
        vm.submitHandler();
      }
    }

    function prevStep() {
      if (vm.selfStep === 'stateGroups') {
        vm.selfStep = vm.steps[0];
        vm.hasSubmit = true;
      }
    }

    function bindGroupByState(groups, state) {
      let stateName = state && state.id ? state.stateName.toLowerCase() : 'international',
        stateGroups = groups.items || [];

      vm.groupByState = _.filter(stateGroups, (g) => _.trim(g.name.toLowerCase()) === stateName);

      if (!vm.groupByState.length) {
        // If state is not found assign default value for 'international state'
        vm.groupByState = _.filter(stateGroups, (g) => _.trim(g.name.toLowerCase()) === 'international');
      }
    }

    function getChildGroups(groupId) {
      return Group.query({
        query: {
          parent: groupId,
          selfReg: true,
          selfRegChild: true,
        },
      }).$promise;
    }
  }
})();
