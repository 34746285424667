(function () {
  angular.module('kmi.lms.train.user.registration').component('dheAttributes', {
    controller: AttributesCtrl,
    controllerAs: 'vm',
    bindings: {
      user: '=user',
      step: '=?step',
      affiliate: '=?affiliate',
    },
    template: require('ajs/custom_modules/train/user/registration/affiliates/components/dhe-attributes.html').default,
  });

  /* @ngInject */
  function AttributesCtrl(_) {
    var vm = this;

    vm.nextStep = nextStep;
    vm.prevStep = prevStep;
    vm.steps = [null, 'customAttributes'];

    function nextStep() {
      var stepId = _.includes(vm.steps, vm.step) ? vm.steps.indexOf(vm.step) : 0;

      if (stepId === 0) {
        vm.steps[0] = vm.step;
      }

      stepId++;
      vm.step = vm.steps[stepId];
    }

    function prevStep() {
      var stepId = vm.steps.indexOf(vm.step);

      stepId--;
      vm.step = vm.steps[stepId];
    }
  }
})();
