(function () {
  angular.module('kmi.lms.train.user.learning').component('trainUserActiveCoursesTabComponent', {
    template: require('ajs/custom_modules/train/user/learning/active-courses-tab.html').default,
    controller: TrainUserActiveCoursesTabController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function TrainUserActiveCoursesTabController($scope, $timeout, $location, courseRegistrationStatus, _) {
    var vm = this,
      registrationStatuses = courseRegistrationStatus.activeSet.concat([courseRegistrationStatus.declined]);

    vm.$onInit = onInit;

    vm.queryParams = {
      include_inactive_courses: true,
      query: angular.toJson({
        statuses: registrationStatuses.concat(courseRegistrationStatus.completedSet), // Append completed set to retrieve "Evaluation Pending" regs
      }),
    };

    vm.registrationStatusNames = courseRegistrationStatus.names;
    vm.columns = {
      status: true,
      creditType: true,
    };
    vm.statusFilter = [];

    vm.changeFilters = changeFilters;
    vm.toggleStatusFilter = toggleStatusFilter;

    function onInit() {
      // Listen for the loading event from the nested views in order to disable UI controls
      $scope.$on('event:dataLoading', function (event, loading) {
        vm.dataLoading = loading;
      });
      vm.sorting = { field: 'course.name', dir: 'desc' };
      vm.searchQuery = $location.search();
      if (vm.searchQuery.filter) {
        vm.sorting = { field: vm.searchQuery.filter, dir: vm.searchQuery.dir };
      }
      $scope.$watch(
        'vm.registrationStatusNames',
        function () {
          initStatusFilters();

          var statusesFromAddress = $location.search();

          if (statusesFromAddress.statuses) {
            statusesFromAddress = statusesFromAddress.statuses.split(',');
            _.map(vm.statusDict, function (status) {
              var mustBeChecked = _.find(statusesFromAddress, function (availableStatus) {
                return availableStatus === status.value.toString();
              });
              status.checked = !!mustBeChecked;
            });
          }

          updateFilters();
        },
        true,
      );

      $scope.$on('$locationChangeSuccess', updateFilters);
    }

    function changeFilters() {
      var searchQuery = $location.search();
      var filteredStatuses = _.map(
        _.filter(vm.statusDict, function (item) {
          return item.checked === true;
        }),
        'value',
      );
      if (filteredStatuses.length) {
        angular.extend(searchQuery, { statuses: filteredStatuses.join(',') });
      } else if (searchQuery.statuses) {
        delete searchQuery.statuses;
      }
      $location.search(searchQuery);
    }

    function updateFilters() {
      vm.statusFilter = _.map(
        _.filter(vm.statusDict, function (item) {
          return item.checked === true;
        }),
        'value',
      );

      // Set all available statuses if there is no custom selection
      if (!vm.statusFilter.length) {
        // Add pseudo filter for evaluation pending statuses
        vm.statusFilter = registrationStatuses;
      }
    }

    function toggleStatusFilter(item) {
      item.checked = !item.checked;
      changeFilters();
    }

    function initStatusFilters() {
      vm.statusDict = [
        {
          label: courseRegistrationStatus.names[courseRegistrationStatus.registrationPending],
          value: courseRegistrationStatus.registrationPending,
        },
        {
          label: courseRegistrationStatus.names[courseRegistrationStatus.pending],
          value: courseRegistrationStatus.pending,
        },
        {
          label: courseRegistrationStatus.names[courseRegistrationStatus.notStarted],
          value: courseRegistrationStatus.notStarted,
        },
        {
          label: courseRegistrationStatus.names[courseRegistrationStatus.preAssessmentPending],
          value: courseRegistrationStatus.preAssessmentPending,
        },
        {
          label: courseRegistrationStatus.names[courseRegistrationStatus.inProgress],
          value: courseRegistrationStatus.inProgress,
        },
        {
          label: courseRegistrationStatus.names[courseRegistrationStatus.assessmentPending],
          value: courseRegistrationStatus.assessmentPending,
        },
        {
          label: courseRegistrationStatus.names[courseRegistrationStatus.evaluationPending],
          value: courseRegistrationStatus.evaluationPending,
        },
      ];
    }
  }
})();
