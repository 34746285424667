(function () {
  angular
    .module('kmi.lms.train.user.registration')
    .directive('affiliateAttributesDirective', affiliateAttributesDirective)
    .run(moduleRun);

  function moduleRun($templateCache) {
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/cdctrain.html',
      require('ajs/custom_modules/train/user/registration/affiliates/cdctrain.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/connecticut.html',
      require('ajs/custom_modules/train/user/registration/affiliates/connecticut.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/detrain.html',
      require('ajs/custom_modules/train/user/registration/affiliates/detrain.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/DHE.html',
      require('ajs/custom_modules/train/user/registration/affiliates/DHE.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/fda.html',
      require('ajs/custom_modules/train/user/registration/affiliates/fda.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/georgia.html',
      require('ajs/custom_modules/train/user/registration/affiliates/georgia.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/ihsctrain.html',
      require('ajs/custom_modules/train/user/registration/affiliates/ihsctrain.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/illinois.html',
      require('ajs/custom_modules/train/user/registration/affiliates/illinois.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/kansas.html',
      require('ajs/custom_modules/train/user/registration/affiliates/kansas.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/maryland.html',
      require('ajs/custom_modules/train/user/registration/affiliates/maryland.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/ma.html',
      require('ajs/custom_modules/train/user/registration/affiliates/ma.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/mi-train.html',
      require('ajs/custom_modules/train/user/registration/affiliates/mi-train.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/mrc.html',
      require('ajs/custom_modules/train/user/registration/affiliates/mrc.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/pa.html',
      require('ajs/custom_modules/train/user/registration/affiliates/pa.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/sd.html',
      require('ajs/custom_modules/train/user/registration/affiliates/sd.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/train3.html',
      require('ajs/custom_modules/train/user/registration/affiliates/train3.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/vbamde.html',
      require('ajs/custom_modules/train/user/registration/affiliates/vbamde.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/virginia.html',
      require('ajs/custom_modules/train/user/registration/affiliates/virginia.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/WYOMING.html',
      require('ajs/custom_modules/train/user/registration/affiliates/WYOMING.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/tn.html',
      require('ajs/custom_modules/train/user/registration/affiliates/tn.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/mt.html',
      require('ajs/custom_modules/train/user/registration/affiliates/mt.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/idaho.html',
      require('ajs/custom_modules/train/user/registration/affiliates/idaho.html').default,
    );
    $templateCache.put(
      'custom_modules/train/user/registration/affiliates/cdphtrain.html',
      require('ajs/custom_modules/train/user/registration/affiliates/cdphtrain.html').default,
    );
  }

  /* @ngInject*/
  function affiliateAttributesDirective() {
    var directive = {
      restrict: 'E',
      link: function (scope, element, attrs) {
        scope.getContentUrl = function () {
          return 'custom_modules/train/user/registration/affiliates/' + attrs.basePath + '.html';
        };

        attrs.$observe('affiliate', function () {
          scope.affiliate = attrs.affiliate;
        });
      },
      template: '<div ng-include="getContentUrl()"></div>',
    };

    return directive;
  }
})();
