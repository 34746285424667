(function () {
  angular
    .module('kmi.lms.train.user.registration', [
      'http-auth-interceptor',
      'kmi.lms.user.common',
      'kmi.lms.train.user',
      'kmi.lms.train.user.edit',
      'kmi.lms.courseProviders',
    ])
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider, $provide) {
    /* @ngInject */
    function providerResolver() {
      return null;
    }

    /* @ngInject */
    function targetPageResolver(menuService) {
      var firstMenuItem = menuService.getFirstMenuState();
      if (firstMenuItem) {
        return firstMenuItem.stateName ? firstMenuItem.stateName : null;
      }
      return null;
    }

    $stateProvider
      .state('prompt.userRegistration', {
        url: '/register?stepCourseProvider',
        component: 'trainUserRegistrationComponent',
        data: {
          label: 'Registration',
          availableFor: ['anonymous'],
        },
        params: {
          stepCourseProvider: null,
          stepAccountCreated: true,
        },
      })
      .state('prompt.userRegistration.policies', {
        url: '/policies',
        component: 'policiesComponent',
        data: {
          label: 'Policies',
          availableFor: ['anonymous'],
        },
      })
      .state('prompt.userRegistration.course_provider', {
        url: '/course-provider',
        component: 'courseProviderSurveyPromptComponent',
        data: {
          label: 'Course provider',
          availableFor: ['regularUser', 'admin', 'anonymous'],
        },
        resolve: {
          provider: providerResolver,
          targetPage: targetPageResolver,
        },
      });

    $provide.decorator('policiesComponentDirective', function ($delegate) {
      var directive = $delegate[0];
      directive.template = require('ajs/custom_modules/train/user/registration/policies.html').default;

      return $delegate;
    });
  }
})();
