(function () {
  angular.module('kmi.lms.train.user.registration').component('courseProviderSurveyPromptComponent', {
    template: require('ajs/custom_modules/train/user/registration/course-provider-template.html').default,
    controller: CourseProviderSurveyPromptController,
    controllerAs: 'vm',
    bindings: {
      provider: '<',
      targetPage: '<',
    },
  });

  function CourseProviderSurveyPromptController(
    notificationService,
    currentUser,
    backUrlService,
    CourseProvider,
    $uiRouterGlobals,
    $state,
  ) {
    const vm = this;
    let providerDraft, savePromise;

    vm.savePending = false;
    vm.formOptions = { submitted: false };
    vm.$onInit = onInit;
    vm.submit = submitCourseProvider;

    function onInit() {
      vm.provider = vm.provider || new CourseProvider();
    }

    function cancelEditCourseProvider() {
      if ($uiRouterGlobals.params.stepCourseProvider && $uiRouterGlobals.params.stepAccountCreated) {
        $state.go('prompt.accountCreated');
      } else if (vm.targetPage) {
        backUrlService.passThroughRedirect(vm.targetPage, { activeView: 'mainDescriptionForm' });
        vm.savePending = false;
      }
    }

    function submitCourseProvider() {
      vm.formOptions.submitted = true;
      if (vm.editCourseProviderSurveyForm.$valid) {
        vm.savePending = true;
        notificationService.info('Saving ...');
        providerDraft = angular.copy(vm.provider);
        providerDraft.userId = currentUser.get().id;
        savePromise = providerDraft.id ? providerDraft.$update() : providerDraft.$save();
        savePromise
          .then(function () {
            notificationService.hideInfo();
            cancelEditCourseProvider();
          })
          .catch(function (error) {
            var message = 'An error occurred sending role request. Please try again later.';
            if (error.status === 403 && error.data && error.data.code) {
              message = error.data.message;
            }
            notificationService.hideInfo();
            notificationService.error(message, 5e3);
          })
          .finally(function () {
            vm.formOptions.submitted = false;
            vm.savePending = false;
          });
      } else {
        notificationService.error('Please fill in all required fields', 2e3);
      }
    }
  }
})();
