(function () {
  angular.module('kmi.lms.train.help', ['globalDependencies']).config(moduleRun).config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider.state('main.helpDirectory', {
      url: '/train-help',
      component: 'trainHelpDirectory',
      data: {
        label: 'TRAIN Help',
        availableFor: ['anonymous', 'regularUser', 'admin'],
      },
    });
  }

  function moduleRun(globalConfig) {
    globalConfig.settings.authorizedStates.push(
      {
        name: 'main.contacts',
        params: {},
      },
      {
        name: 'main.help.policies',
        params: {},
      },
      {
        name: 'main.help.about',
        params: {},
      },
    );
  }
})();
