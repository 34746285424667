(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.registration:affiliateCustomAttributes
   *
   * @description
   * Custom account creation attributes selection by user groups.
   */
  angular.module('kmi.lms.train.user.registration').directive('affiliateCustomAttributes', affiliateCustomAttributes);

  /* @ngInject */
  function affiliateCustomAttributes() {
    return {
      restrict: 'EA',
      controller: CustomAttributesCtrl,
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        user: '=user',
        formOptions: '=?formOptions',
        step: '=?step',
        hasCategories: '=?',
        categoryIds: '=?',
        requiredOnly: '<?',
        excludeAttributesGroups: '<?excludeAttributesGroups',
      },
      template:
        require('ajs/custom_modules/train/user/registration/affiliates/components/affiliate-custom-attributes.html')
          .default,
    };
  }

  /* @ngInject */
  function CustomAttributesCtrl(
    $scope,
    $element,
    $q,
    $http,
    _,
    attributesService,
    customUserAttributesService,
    serverErrorHandlerService,
    notificationService,
    $interval,
  ) {
    var vm = this;
    var defer = null;

    vm.categories = [];
    vm.formOptions = { submitted: false };
    vm.componentLoaded = false;
    vm.initForm = attributesService.initForm;
    vm.categoryId = null;

    vm.nextStep = nextStep;
    vm.prevStep = prevStep;
    vm.$onInit = onInit;

    function onInit() {
      activate();
    }

    function activate() {
      $scope.$watch(
        'vm.user.groups',
        function (newValue, oldValue) {
          vm.customAttributeForm.$setValidity('attributes', false);
          vm.categories = [];

          if (newValue && newValue.length) {
            if (!angular.equals(_.sortBy(newValue), _.sortBy(oldValue))) {
              if (defer && _.some($http.pendingRequests, { timeout: defer.promise })) {
                defer.resolve();
              }

              loadCustomUserAttributes().then(function () {
                vm.customAttributeForm.$setValidity('attributes', true);
              }, angular.noop);
            }
          }
        },
        true,
      );

      loadCustomUserAttributes().then(function (res) {
        if (res.length) {
          vm.customAttributeForm.$setValidity('attributes', true);
        }
      }, angular.noop);

      $scope.$on('event:user:beforeSave', prepareAttributesForSave);

      $scope.$on('$destroy', function () {
        if (vm.customAttributeForm) {
          vm.customAttributeForm.$setValidity('attributes', true);
        }
      });
    }

    function loadCustomUserAttributes() {
      defer = $q.defer();
      vm.componentLoaded = false;
      vm.hasCategories = false;

      var attributesParams = { withAttributes: true, groups: angular.toJson(_.map(vm.user.groups, 'id')) };

      if (vm.excludeAttributesGroups) {
        attributesParams.excludeGroups = angular.toJson(vm.excludeAttributesGroups);
      }

      if (vm.categoryIds) {
        attributesParams.categoryIds = angular.toJson(vm.categoryIds);
      }

      return customUserAttributesService.getAttributeCategories(attributesParams, vm.user.id, defer.promise).then(
        function (res) {
          vm.componentLoaded = true;

          vm.categories = vm.requiredOnly ? _getRequiredOnly(res) : res;

          if (vm.categories.length) {
            vm.step = 0;
            vm.categoryId = vm.categories[vm.step].id;
            vm.hasCategories = true;
          }

          return res;
        },
        function (reason) {
          serverErrorHandlerService.handleForbiddenError(reason).then(null, angular.noop);
        },
      );
    }

    function nextStep(form) {
      vm.formOptions.submitted = true;
      vm.validationPending = true;

      let validationPending = $interval(function () {
        if (!form.$pending) {
          if (!$http.pendingRequests.length) {
            $interval.cancel(validationPending);
            if (form.$valid) {
              vm.formOptions.submitted = false;
              vm.step++;
              vm.categoryId = vm.categories[vm.step].id;

              $element[0].scrollIntoView();
            } else {
              notificationService.error('Please fill in all required fields', 2e3);
            }

            vm.validationPending = false;
          }
        }
      }, 100);
    }

    function prevStep(form) {
      vm.step--;
      form.$setValidity('attributes', false);
      vm.categoryId = vm.categories[vm.step].id;
    }

    function prepareAttributesForSave(event, model) {
      model.attributes = [];

      attributesService.prepareAttributesForSave(model, vm.categories);
    }

    function _getRequiredOnly(categories) {
      return _.map(
        _.filter(categories, function (cat) {
          return cat.primaryRequired || _.some(cat.attributes, { required: true });
        }),
        function (cat) {
          cat.attributes = cat.primaryRequired ? cat.attributes : _.filter(cat.attributes, { required: true });
          return cat;
        },
      );
    }
  }
})();
