(function () {
  angular.module('kmi.lms.train.user').component('availableGroupsList', {
    template: require('ajs/custom_modules/train/user/groups/available-groups-list.html').default,
    controller: AvailableGroupsListController,
    controllerAs: 'vm',
    bindings: {
      groups: '=',
      groupSelected: '&onGroupSelected',
    },
  });

  /* @ngInject */
  function AvailableGroupsListController($scope) {
    var vm = this;

    vm.selectGroup = selectGroup;

    activate();

    function activate() {
      $scope.$watch('vm.groups', function () {
        vm.groupName = '';
      });
    }

    function selectGroup(group) {
      vm.groupSelected({ group: group });
    }
  }
})();
