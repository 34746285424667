(function () {
  angular.module('kmi.lms.train.home').factory('affiliatesService', affiliatesService);

  /* @ngInject */
  function affiliatesService($http) {
    return {
      get: function () {
        return $http.get('/a/user/geo/affiliates/').then(function (response) {
          return response.data;
        });
      },
    };
  }
})();
