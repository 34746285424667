(function () {
  angular.module('kmi.lms.train.user.registration').directive('userAttributesDirective', userAttributesDirective);
  /* @ngInject*/
  function userAttributesDirective() {
    var directive = {
      scope: true,
      template: require('ajs/custom_modules/train/user/registration/user-attributes.html').default,
    };
    return directive;
  }
})();
