(function () {
  angular.module('kmi.lms.train.search').factory('trainSearchFilters', trainSearchFilters);

  function trainSearchFilters(
    competencyService,
    searchFilters,
    customCourseAttributesService,
    $timeout,
    $state,
    $location,
    courseSessionOptionsService,
    userGroupsService,
    currentUser,
    _,
  ) {
    var service = {
      CompetencyNodeFilter: competencyNodeFilter(),
      SessionLabelsFilter: sessionLabelsFilter(),
      GroupTagsFilter: groupTagsFilter(),
      ObjectFilter: objectFilter(),
      CourseAttributeFilter: courseAttributeFilter(),
      PerlcCourseAttributeFilter: perlcCourseAttributeFilter(),
      GroupsFilter: groupsFilter(),
      SingleGroupFilter: singleGroupFilter(),
    };

    return service;

    function singleGroupFilter() {
      return searchFilters.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            term: 'group_id',
            label: 'Affiliate',
            mode: 'singleChoice', //For backward compatibility
            multiChoice: false,
            items: [],
            dependency: [
              {
                filter: searchFilters.ObjectFilter,
                condition: 'course',
              },
              function () {
                return !currentUser.get().anonymous;
              },
            ],
          });
        },
        load: function () {
          return userGroupsService.getStates().then(
            function (response) {
              this.items = _.map(response.data, function (item) {
                return {
                  id: item.id,
                  text: item.name,
                  term: 'group_id',
                  value: item.id + '',
                };
              });
              this._initSelectedItems();
            }.bind(this),
          );
        },
      });
    }

    function objectFilter() {
      return searchFilters.SearchFilterBase.extend({
        init: function (options) {
          this._super();

          angular.extend(
            this,
            {
              label: 'Search By',
              term: 'type',
              mode: 'singleChoice', //For backward compatibility
              multiChoice: false,
              open: true,
              items: [
                {
                  text: 'Courses',
                  value: 'course',
                },
                {
                  text: 'Training Plans',
                  value: 'learning_series',
                },
                {
                  text: 'Calendar Events',
                  value: 'nonTrainEvent',
                },
                {
                  text: 'Resources',
                  value: 'resource',
                },
                {
                  text: 'Discussions',
                  value: 'discussion',
                },
              ],
              clearItemsLabel: '',
            },
            options,
          );
        },
      });
    }

    function competencyNodeFilter() {
      return searchFilters.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            label: 'Competencies and Capabilities',
            mode: 'popupMultiChoice',
            term: 'competency_node_id',
            combineLabels: true,
            popupOptions: {
              component: 'competencyFilter',
            }, //For backward compatibility
            multiChoice: true,
            items: [],
            dependency: {
              filter: searchFilters.ObjectFilter,
              condition: 'course',
            },
            combineTextFormatter: function () {
              return this.selectedItems.length + ' Competencies & capabilities Selected';
            },
          });
        },
        load: function () {
          function getChildNodes(nodes) {
            var childNodes = _.reduce(
              nodes,
              function (flattened, node) {
                return flattened.concat(node.nodes);
              },
              [],
            );
            if (childNodes.length > 0) {
              return childNodes.concat(getChildNodes(childNodes));
            }
            return [];
          }

          return competencyService.getModelsStructure().then(
            function (response) {
              this.competencies = response.data;
              var list = getChildNodes(this.competencies);

              this.items = _.map(list, function (item) {
                return {
                  id: item.id,
                  text: item.title,
                  value: item.id + '',
                };
              });

              this._initSelectedItems();
            }.bind(this),
          );
        },
      });
    }

    function courseAttributeFilter() {
      return function (options) {
        options = options || {};

        var Filter = searchFilters.SearchFilterBase.extend({
          getSelectedOptions: function () {
            var search = $location.search();
            this.selectedOptions = search[this.term] && angular.fromJson(search[this.term])[this.category_id];
          },
          exec: function (value, term) {
            var categoryId = this.category_id,
              search = $location.search(),
              existingConditions = {};

            value = value.toString().trim();
            term = term || this.term;

            if (search[term]) {
              existingConditions = angular.fromJson(search[term]);
            }

            if (!existingConditions[categoryId]) {
              existingConditions[categoryId] = [];
            }

            if (
              !_.find(existingConditions[categoryId], function (i) {
                return i.trim() === value;
              })
            ) {
              if (this.multiChoice) {
                existingConditions[categoryId].push(value);
              } else {
                existingConditions[categoryId] = [value];
              }
            } else {
              existingConditions[categoryId] = _.without(existingConditions[categoryId], value);
            }

            existingConditions[categoryId] = _.uniq(existingConditions[categoryId]);
            if (!existingConditions[categoryId].length) {
              delete existingConditions[categoryId];
            }

            if (Object.keys(existingConditions).length > 0) {
              search[term] = angular.toJson(existingConditions);
            } else {
              //remove empty condition
              delete search[term];
            }

            //update query string
            $location.search(search);
          },
          clearSelection: function () {
            var search = $location.search();

            if (search[this.term]) {
              var conditions = angular.fromJson(search[this.term]);
              delete conditions[this.category_id];

              if (!Object.keys(conditions).length) {
                delete search[this.term];
              } else {
                search[this.term] = angular.toJson(conditions);
              }
            }

            $location.search(search);
          },
          init: function () {
            this._super();

            angular.extend(this, {
              label: options.label || 'Course Attribute Filter',
              mode: 'popupMultiChoice',
              term: options.term || 'course_attribute_id',
              category_id: options.category_id || 1,
              popupOptions: {
                component: 'courseAttributeFilter',
              }, //For backward compatibility
              multiChoice: true,
              items: [],
              dependency: {
                filter: searchFilters.ObjectFilter,
                condition: 'course',
              },
            });
          },
          load: function () {
            return customCourseAttributesService.getTypes(this.category_id).then(
              function (response) {
                this.items = _.map(response.data, function (item) {
                  return {
                    id: item.id,
                    text: item.name,
                    value: item.id + '',
                  };
                });

                this._initSelectedItems();
              }.bind(this),
            );
          },
        });

        return new Filter();
      };
    }

    function perlcCourseAttributeFilter() {
      return function (options) {
        options = options || {};

        var Filter = searchFilters.SearchFilterBase.extend({
          getSelectedOptions: function () {
            var search = $location.search();
            this.selectedOptions = search[this.term] && angular.fromJson(search[this.term])[this.category_id];
          },
          exec: function (value, term) {
            var categoryId = this.category_id,
              search = $location.search(),
              existingConditions = {};

            value = value.toString().trim();
            term = term || this.term;

            if (search[term]) {
              existingConditions = angular.fromJson(search[term]);
            }

            if (!existingConditions[categoryId]) {
              existingConditions[categoryId] = [];
            }

            if (
              !_.find(existingConditions[categoryId], function (i) {
                return i.trim() === value;
              })
            ) {
              if (this.multiChoice) {
                existingConditions[categoryId].push(value);
              } else {
                existingConditions[categoryId] = [value];
              }
            } else {
              existingConditions[categoryId] = _.without(existingConditions[categoryId], value);
            }

            existingConditions[categoryId] = _.uniq(existingConditions[categoryId]);
            if (!existingConditions[categoryId].length) {
              delete existingConditions[categoryId];
            }

            if (Object.keys(existingConditions).length > 0) {
              search[term] = angular.toJson(existingConditions);
            } else {
              //remove empty condition
              delete search[term];
            }

            //update query string
            $location.search(search);
          },
          clearSelection: function () {
            var search = $location.search();

            if (search[this.term]) {
              var conditions = angular.fromJson(search[this.term]);
              delete conditions[this.category_id];

              if (!Object.keys(conditions).length) {
                delete search[this.term];
              } else {
                search[this.term] = angular.toJson(conditions);
              }
            }

            $location.search(search);
          },
          init: function () {
            this._super();

            angular.extend(this, {
              label: options.label,
              mode: 'popupMultiChoice',
              term: 'perlc_course_attribute_id',
              popupOptions: {
                component: 'courseAttributeFilter',
              }, //For backward compatibility
              multiChoice: true,
              items: [],
              dependency: {
                filter: searchFilters.ObjectFilter,
                condition: 'course',
              },
            });
          },
          load: function () {
            return customCourseAttributesService.getCategories({ categoryId: [60] }, true).then(
              function (response) {
                if (_.isArray(response.data) && response.data.length) {
                  var cat = response.data[0];

                  this.label = cat.name;

                  return customCourseAttributesService.getTypes(cat.id).then(
                    function (response) {
                      this.items = _.map(response.data, function (item) {
                        return {
                          id: item.id,
                          text: item.name,
                          value: item.id + '',
                        };
                      });

                      this._initSelectedItems();
                    }.bind(this),
                  );
                }
              }.bind(this),
            );
          },
        });

        return new Filter();
      };
    }

    function sessionLabelsFilter() {
      return searchFilters.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            label: 'Region',
            appearance: 'button',
            mode: 'popupMultiChoice',
            term: 'session_label_id',
            combineLabels: true,
            popupOptions: {
              component: 'sessionLabelFilter',
            }, //For backward compatibility
            multiChoice: true,
            items: [], //dependency: {
            //  filter: searchFilters.ObjectFilter,
            //  condition: 'course'
            //},
            combineTextFormatter: function () {
              return this.selectedItems.length + ' Regions Selected';
            },
          });
        },
        load: function () {
          function getChildNodes(nodes) {
            var childNodes = _.reduce(
              nodes,
              function (flattened, node) {
                return flattened.concat(node.items || []);
              },
              [],
            );

            if (childNodes.length > 0) {
              return childNodes.concat(getChildNodes(childNodes));
            }
            return [];
          }

          return courseSessionOptionsService.getLabels().then(
            function (labels) {
              this.sessionLabels = labels;
              var list = getChildNodes(this.sessionLabels);

              this.items = _.map(list, function (item) {
                return {
                  id: item.id,
                  text: item.name,
                  value: item.id + '',
                };
              });

              this._initSelectedItems();
            }.bind(this),
          );
        },
      });
    }

    function groupTagsFilter() {
      return searchFilters.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            label: 'Discipline',
            appearance: 'button',
            mode: 'popupMultiChoice',
            term: 'group_tag_id',
            combineLabels: true,
            popupOptions: {
              component: 'groupTagsSearchFilter',
            }, //For backward compatibility
            multiChoice: true,
            items: [], //dependency: {
            //  filter: searchFilters.ObjectFilter,
            //  condition: 'course'
            //},
            combineTextFormatter: function () {
              return this.selectedItems.length + ' Disciplines Selected';
            },
          });
        },
        load: function () {
          function getChildNodes(nodes) {
            var childNodes = _.reduce(
              nodes,
              function (flattened, node) {
                return flattened.concat(node.items || []);
              },
              [],
            );

            if (childNodes.length > 0) {
              return childNodes.concat(getChildNodes(childNodes));
            }
            return [];
          }

          return userGroupsService.getGroupTags().then(
            function (tags) {
              this.groupTags = tags;
              var list = getChildNodes(this.groupTags);

              this.items = _.map(list, function (item) {
                return {
                  id: item.id,
                  text: item.name,
                  value: item.id + '',
                };
              });

              this._initSelectedItems();
            }.bind(this),
          );
        },
      });
    }

    function groupsFilter() {
      var isAnonymous = currentUser.get().anonymous;

      return searchFilters.MultipleTermFilter.extend({
        _initSelectedItems: function () {
          this._super();

          if (
            isAnonymous &&
            _.some(this.selectedItems, {
              term: 'user_courses',
              value: 'true',
            })
          ) {
            $timeout(function () {
              $state.go('prompt.login', null, { location: 'replace' });
            });
          }
        },
        init: function (options) {
          this._super();

          angular.extend(
            this,
            {
              label: 'Filters',
              appearance: 'button',
              mode: 'multiChoice', //For backward compatibility
              multiChoice: true,
              open: true,
              items: [],
            },
            options,
          );
        },
        load: function () {
          return userGroupsService.getStates().then(
            function (response) {
              this.items = [
                {
                  text: 'Your events',
                  term: 'user_courses',
                  value: 'true',
                },
              ].concat(
                _.map(response.data, function (item) {
                  return {
                    id: item.id,
                    text: item.name + ' events',
                    term: 'group_id',
                    value: item.id + '',
                  };
                }),
              );

              this._initSelectedItems();
            }.bind(this),
          );
        },
      });
    }
  }
})();
