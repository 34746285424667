(function () {
  angular
    .module('kmi.lms.train.user.edit')
    .component('trainUserSelfGroupSelector', {
      template: require('ajs/custom_modules/train/user/groups/group-selector.html').default,
      controller: UserGroupSelectionController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        rootGroups: '=',
        defaultState: '=',
        accountSettings: '=',
        lastSelectedGroup: '=',
        userGroups: '=',
        selectedGroupPath: '=',
        deferredGroups: '=?',
      },
    })
    .run(moduleRun);

  function moduleRun($templateCache) {
    $templateCache.put(
      'custom_modules/train/user/groups/group-help.html',
      require('ajs/custom_modules/train/user/groups/group-help.html').default,
    );
  }

  /* @ngInject */
  function UserGroupSelectionController($scope, _, Group, portalGroups, groupService, $q, $uibModal) {
    var vm = this,
      defaultPath = [],
      excludedGroups;

    // Methods
    vm.onGroupSelected = onGroupSelected; // Triggers on group selection
    vm.rollBackToGroup = rollBackToGroup; // Invokes within breadcrumbs
    vm.confirmSelections = confirmSelections; // Confirms currently selected path
    vm.getSelectedDescendants = getSelectedDescendants;

    activate();

    vm.$onInit = onInit;

    function onInit() {
      excludedGroups = getExcludedGroups();

      vm.editMode = !!vm.lastSelectedGroup; // We deal with edit mode when group provided initially
      vm.selectedGroupPath = vm.selectedGroupPath || []; // List of all selected parents of lastSelectedGroup
      vm.deferredGroups = []; // List of group branches which will be suggested to user after main branch selection

      activate();
    }

    function activate() {
      if (vm.lastSelectedGroup) {
        // Bind data for the initially selected group
        onGroupSelected(vm.lastSelectedGroup);
      }

      // Extract group category in the currently selected group.
      $scope.$watchCollection('vm.lastSelectedGroup.items', extractGroupCategory);

      // Disable groups for selection when it already exists in such branch.
      _.each(vm.rootGroups, function (rootGroup) {
        rootGroup.disabled = _.some(vm.user.groups, function (ug) {
          return (
            (rootGroup.id !== portalGroups.state || !nonStatePortalAssignment(ug)) &&
            ug.ileft >= rootGroup.ileft &&
            ug.iright <= rootGroup.iright &&
            ug.selfRegistrationAllowed
          );
        });

        function nonStatePortalAssignment(userGroup) {
          return _.some(vm.rootGroups, function (rootGroup) {
            return (
              rootGroup.id !== portalGroups.state &&
              userGroup.selfRegistrationAllowed &&
              userGroup.ileft >= rootGroup.ileft &&
              userGroup.iright <= rootGroup.iright
            );
          });
        }
      });

      function extractGroupCategory() {
        // Category is always unique because we divide branch to the several paths in case when branch contains more than one group category
        if (_.get(vm, 'lastSelectedGroup.items.length')) {
          vm.currentGroupCategory = vm.lastSelectedGroup.items[0].category;
        } else {
          vm.currentGroupCategory = null;
        }
      }
    }

    /**
     * With those models we can recover group selection position from outside
     */
    function notifyGroupSelectionChanged() {
      $scope.$emit('event:groupSelectionPositionChanged', {
        lastSelectedGroup: vm.lastSelectedGroup,
        selectedGroupPath: vm.selectedGroupPath,
        deferredGroups: vm.deferredGroups,
        isRolledBack: vm.isRolledBack,
      });

      vm.isRolledBack = null;
    }

    /**
     * @description
     * 1. Add group to the end of the selected path
     * 2. Request group descendants which has selfReg groups on the leafs.
     * 3. Extract excluded groups
     * @param group
     * @param skipDefaults
     */
    function onGroupSelected(group, skipDefaults) {
      vm.groupProcessing = true;

      // At the start point apply predefined selection if it's defined for the affiliate (DefaultSelfRegGroup)
      // or bind a group for edit
      if (!vm.selectedGroupPath.length) {
        initDefaultGroups()
          .then(function () {
            return !skipDefaults ? predefineGroup(group) : group;
          })
          .then(applyGroupSelection);
      } else {
        applyGroupSelection(group);
      }

      function applyGroupSelection(group) {
        // Add group to the selected branch path
        vm.selectedGroupPath.push(group);

        // Fetch group descendants
        if (!group.items) {
          loadGroupDescendants(group);
        } else {
          // Bind selected group and it's descendants
          suggestGroups(group);
        }
      }
    }

    /**
     * @description
     * Fetches default group path if default group for the affiliate is defined.
     * @returns {*}
     */
    function initDefaultGroups() {
      if (!defaultPath.length) {
        // Fetch ancestors for the default group and store such default path.
        // For MRC get group with the same name as default group and also fetch it's ancestors and store default path.
        return $q.all(
          vm.defaultState
            ? [
                groupService.getGroupAncestors(vm.defaultState.id).then(function (ancestors) {
                  defaultPath.push({ path: ancestors, group: angular.extend(vm.defaultState, { locked: true }) });
                }),
                getMrcGroupByName(vm.defaultState.name).then(function (mrcGroup) {
                  if (mrcGroup) {
                    return groupService.getGroupAncestors(mrcGroup.id).then(function (ancestors) {
                      defaultPath.push({ path: ancestors, group: angular.extend(mrcGroup, { locked: true }) });
                    });
                  }
                }),
              ]
            : [],
        );
      }

      return $q.when(defaultPath);
    }

    function predefineGroup(group) {
      if (!isPortalGroup(group)) {
        // Selected group is not from the root level
        return bindGroupPath(group);
      } else {
        if (group.id === portalGroups.state) {
          // Find state selected by user. We must suggest for user to select groups under such state
          var selectedState = _.find(vm.user.groups, function (ug) {
            return ug.categoryId === 12;
          });

          if (selectedState && getGroupPortal(selectedState) === portalGroups.state) {
            // Bind previously selected state
            return bindGroupPath(selectedState);
          }
        }

        return $q.when(bindPortalDefaults());
      }

      function bindGroupPath(targetGroup) {
        return groupService
          .getGroupAncestors(targetGroup.id)
          .then(selectGroupPath)
          .then(function () {
            return targetGroup;
          });
      }

      function selectGroupPath(groups) {
        vm.selectedGroupPath = trimAbovePortal(groups);

        // Lock groups. User should not be able to select any group from the predefined path.
        _.each(vm.selectedGroupPath, function (g) {
          if (groupPredefined(g)) {
            g.locked = true;
          }
        });

        function groupPredefined(group) {
          return _.some(defaultPath, function (dg) {
            return dg.group.ileft > group.ileft && dg.group.iright < group.iright;
          });
        }

        function trimAbovePortal(groups) {
          var portals = _.values(portalGroups);

          for (var i = 0; i < portals.length; i++) {
            var groupIndex = _.indexOf(_.map(groups, 'id'), portals[i]);

            if (groupIndex > -1) {
              groups = _.drop(groups, groupIndex);
            }
          }

          return groups;
        }
      }

      function bindPortalDefaults() {
        for (var i = 0; i < defaultPath.length; i++) {
          if (group.id === portalGroups.mrc) {
            if (defaultPath[i].group.categoryId === 120) {
              selectGroupPath(defaultPath[i].path);
              return defaultPath[i].group;
            }
          } else {
            if (group.ileft <= defaultPath[i].group.ileft && group.iright >= defaultPath[i].group.iright) {
              selectGroupPath(defaultPath[i].path);
              return defaultPath[i].group;
            }
          }
        }

        return group;
      }
    }

    function getMrcGroupByName(name) {
      return Group.query({ query: { group_name: name, category: 120 } }).$promise.then(function (response) {
        return _.head(response.items);
      });
    }

    function loadGroupDescendants(group) {
      // Request all groups which has group.id as a parent and allows selfRegistration on the any depth
      return Group.query(
        {
          query: {
            parent: group.id,
            selfReg: true,
            selfRegChild: true,
          },
        },
        function (response) {
          // Filter out excluded groups and leave only default states if available.
          group.items = _.filter(response.items, function (g) {
            return (
              !_.includes(excludedGroups, g.id) && // Excluded groups
              g.id !== group.id
            ); // Group itself
          });

          suggestGroups(group);
        },
      ).$promise;
    }

    /**
     * @description
     * Returns list of groupIDs from 'MyAccount.ExcludedGroups' setting
     * @returns {Array}
     */
    function getExcludedGroups() {
      var excludedGroups = vm.accountSettings['MyAccount.ExcludedGroups'];
      if (excludedGroups) {
        excludedGroups = _.map(excludedGroups.split(','), function (item) {
          return parseInt(_.trim(item), 10); // Convert to int
        });

        return _.uniq(excludedGroups);
      }

      return [];
    }

    /**
     * @description
     * Binds lastSelectedGroup and it's descendants
     * @param group
     */
    function suggestGroups(group) {
      // This is not the final branch in the path.
      if (group.items.length) {
        // Group groups by categories.
        // Each category should be suggested independently.
        var categoryGroups = _.groupBy(group.items, 'categoryId');
        var keys = _.map(Object.keys(categoryGroups), Number); // Get all categoryIds

        // Save additional branches to the deferred lists.
        if (keys.length > 1) {
          // Filter out already selected group categories
          keys = _.filter(keys, function (k) {
            return !_.some(vm.userGroups, function (ug) {
              return (
                ug.ileft > group.ileft &&
                ug.iright < group.iright &&
                _.some(categoryGroups[k], function (cg) {
                  return cg.ileft <= ug.ileft && cg.iright >= ug.iright;
                })
              );
            });
          });

          keys = _.sortBy(keys);

          // Create a copy of the current group and set "items" only from the one branch.
          var groupClone = angular.copy(group);
          if (keys.length) {
            groupClone.items = categoryGroups[keys[0]];
          }

          // Save last selected group
          vm.lastSelectedGroup = groupClone;

          // Create copies of the current group and set "items" to the corresponding category groups.
          for (var i = 1; i < keys.length; i++) {
            var deferredGroup = angular.copy(group);
            deferredGroup.items = categoryGroups[keys[i]];

            var deferredPath = _.initial(vm.selectedGroupPath);
            deferredPath.push(deferredGroup);

            vm.deferredGroups.push(deferredPath);
          }
        } else {
          // Save last selected group
          vm.lastSelectedGroup = group;
        }
      } else {
        vm.lastSelectedGroup = group;
      }

      notifyGroupSelectionChanged();
      vm.groupProcessing = false;
    }

    function isPortalGroup(group) {
      return _.includes(_.values(portalGroups), group.id);
    }

    function isStateGroup(group) {
      return group.categoryId === 12 && group.parentId === portalGroups.state;
    }

    /**
     * @description
     * Revert group selection to the any group in the current group path
     * @param group
     */
    function rollBackToGroup(group) {
      vm.isRolledBack = true;

      if (group) {
        // Find index of the target group
        var index = vm.selectedGroupPath.indexOf(group),
          groupsToRemove = _.drop(vm.selectedGroupPath, index + 1);

        var samePathGroups = [];
        _.each(vm.userGroups, function (ug) {
          if (
            _.some(groupsToRemove, function (rg) {
              return rg.ileft <= ug.ileft && rg.iright >= ug.iright;
            })
          ) {
            samePathGroups.push(ug);
          }
        });

        if (samePathGroups.length) {
          var modalInstance = $uibModal.open({
            component: 'trainUserGroupsRemoveConfirmationModal',
            resolve: {
              affiliatePath: function () {
                return group.path;
              },
            },
            keyboard: false,
          });

          modalInstance.result.then(function () {
            vm.userGroups = _.without.apply(null, [vm.userGroups].concat(samePathGroups));
            removeDescendants();
          });
        } else {
          removeDescendants();
        }
      } else {
        clearAll();
      }

      function removeDescendants() {
        // Remove groups which will be deleted from deferred queue
        for (var i = index; i < vm.selectedGroupPath.length; i++) {
          removeDeferredGroups(vm.selectedGroupPath[i]);
        }

        _.remove(vm.userGroups, function (ug) {
          return _.some(groupsToRemove, function (rg) {
            return rg.ileft <= ug.ileft && rg.iright >= ug.iright;
          });
        });

        // Clear selection (remove groups) after the target group.
        vm.selectedGroupPath.splice(index, vm.selectedGroupPath.length - index);

        // Set target group as lastSelected in order to bind group data and it's children
        group.items = null;
        onGroupSelected(group, true);
      }
    }

    function removeDeferredGroups(group) {
      _.each(vm.deferredGroups, function (dg) {
        var lastGroup = _.last(dg);

        if (lastGroup.id === group.id) {
          vm.deferredGroups = _.without(vm.deferredGroups, dg);
        }
      });
    }

    /**
     * @description
     * returns selected group descendants and group
     * @param group
     * @returns {Array}
     */
    function getSelectedDescendants(group) {
      return _.filter(vm.userGroups, function (g) {
        return g.parentId === group.id || g.id === group.id;
      });
    }

    /**
     * @description
     * returns new array without group and it descendants if they where selected
     * @param group
     * @returns {Array}
     */
    function removeSelectedDescendants(group) {
      vm.userGroups = _.filter(vm.userGroups, function (g) {
        return g.parentId !== group.id && g.id !== group.id;
      });
    }

    /**
     * @description
     * Applies selected group to the list of groups which will be assigned to user.
     * Switches to the last deferred group path if exists.
     */
    function confirmSelections() {
      // Clear assigned groups if selfRegistrationAllowed state group was selected
      if (isStateGroup(vm.lastSelectedGroup)) {
        vm.userGroups = [];
      }

      if (vm.deferredGroups.length && _.last(_.last(vm.deferredGroups)).id === vm.lastSelectedGroup.id) {
        vm.deferredGroups = _.filter(vm.deferredGroups, function (g) {
          return _.last(g).id !== vm.lastSelectedGroup.id;
        });
        removeSelectedDescendants(vm.lastSelectedGroup);
      }

      // Assign group for user.
      vm.userGroups.push(vm.lastSelectedGroup);

      // Suggest groups from another branches of the currently selected groups.
      if (vm.deferredGroups.length && !isStateGroup(vm.lastSelectedGroup)) {
        vm.selectedGroupPath = vm.deferredGroups.pop();
        vm.lastSelectedGroup = _.last(vm.selectedGroupPath);
        vm.deferredGroup = vm.lastSelectedGroup;
      } else {
        clearAll();
      }

      notifyGroupSelectionChanged();
    }

    function clearAll() {
      vm.lastSelectedGroup = null;
      vm.deferredGroup = null;
      vm.deferredGroups = [];
      vm.selectedGroupPath = [];
      vm.currentGroupCategory = null;
    }

    function getGroupPortal(group) {
      var portal = _.find(vm.rootGroups, function (rootGroup) {
        return (
          rootGroup.id !== portalGroups.state && group.ileft >= rootGroup.ileft && group.iright <= rootGroup.iright
        );
      });

      if (!portal) {
        portal = _.find(vm.rootGroups, function (rootGroup) {
          return (
            rootGroup.id === portalGroups.state && group.ileft >= rootGroup.ileft && group.iright <= rootGroup.iright
          );
        });
      }

      return portal || group;
    }
  }
})();
