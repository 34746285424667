(function () {
  angular.module('kmi.lms.train.user').component('trainEditUserGroupComponent', {
    template: require('ajs/custom_modules/train/user/groups/edit-group.html').default,
    controller: TrainEditUserGroupController,
    controllerAs: 'vm',
    bindings: {
      user: '<',
      group: '<',
      rootGroups: '<',
      defaultState: '<',
      accountSettings: '<',
    },
  });

  /* @ngInject */
  function TrainEditUserGroupController($scope, $timeout, backUrlService, _, portalGroups) {
    var vm = this,
      groupsForEdit,
      assignedUserGroups;

    vm.startSelector = true;

    vm.goToAccountEdit = goToAccountEdit;
    vm.saveGroups = saveGroups;

    vm.$onInit = onInit;

    function onInit() {
      // Init lastSelectedGroup with the provided group.
      // It'll be passed to groups selector directive and predefined group tree
      vm.lastSelectedGroup = vm.group;
      vm.initialGroup = vm.group;

      var groupPortal = getGroupPortal(vm.group);

      // Save all user groups from the same portal as provided groups to the edit view
      assignedUserGroups = _.filter(vm.user.groups, function (g) {
        return g.id !== vm.group.id && g.selfRegistrationAllowed && groupPortal === getGroupPortal(g);
      });

      assignedUserGroups = _.union([vm.group], assignedUserGroups);

      // Save all groups ids which are connected for edit
      groupsForEdit = _.map(assignedUserGroups, 'id');

      _.each(vm.rootGroups, function (rootGroup) {
        rootGroup.locked = _.some(assignedUserGroups, function (ug) {
          return ug.ileft >= rootGroup.ileft && ug.iright <= rootGroup.iright && ug.selfRegistrationAllowed;
        });
      });

      vm.userGroups = [];

      $scope.$watch(
        'vm.userGroups',
        function () {
          reinitSelector();
        },
        true,
      );
    }

    /**
     * @description
     * Reinit groups selector to begin select groups anew if userGroups were deleted
     */
    function reinitSelector() {
      if (vm.userGroups && !vm.userGroups.length) {
        vm.startSelector = false;
        $timeout(function () {
          vm.lastSelectedGroup = vm.group;
          // If edit portal groups, init selectedGroupPath with portal value
          vm.selectedGroupPath = isNonStateGroup(vm.group) ? [] : [vm.rootGroups[0]];
          vm.startSelector = true;
        });
      }
    }

    function isNonStateGroup(group) {
      return _.includes(_.map(_.values(vm.rootGroups), 'id'), group.id);
    }

    function getGroupPortal(group) {
      var portal = _.find(vm.rootGroups, function (rootGroup) {
        return (
          rootGroup.id !== portalGroups.state && group.ileft >= rootGroup.ileft && group.iright <= rootGroup.iright
        );
      });

      if (!portal) {
        portal = _.find(vm.rootGroups, function (rootGroup) {
          return (
            rootGroup.id === portalGroups.state && group.ileft >= rootGroup.ileft && group.iright <= rootGroup.iright
          );
        });
      }

      return portal || group;
    }

    function goToAccountEdit() {
      backUrlService.passThroughRedirect('edit.account');
    }

    function saveGroups() {
      vm.user.groups = _.filter(vm.user.groups, function (ug) {
        return (
          !isSameAffiliateAsInitial(ug) ||
          (!_.includes(groupsForEdit, ug.id) && (ug.selfRegistrationAllowed || !someSelectedGroupsIsChildFor(ug)))
        );
      });

      // Add all selected groups
      vm.user.groups = _.uniqBy(vm.user.groups.concat(vm.userGroups), 'id');

      goToAccountEdit();
    }

    function isSameAffiliateAsInitial(ug) {
      return vm.initialGroup.ileft <= ug.ileft && vm.initialGroup.iright >= ug.iright;
    }

    function someSelectedGroupsIsChildFor(group) {
      _.find(vm.userGroups, function (selectedGroup) {
        return group.ileft < selectedGroup.ileft && group.iright > selectedGroup.iright;
      });
    }
  }
})();
