(function () {
  angular.module('elmsSpaApp').component('trainFooter', {
    template: require('ajs/custom_modules/train/app/train-footer.html').default,
    controller: TrainFooterController,
    controllerAs: 'vm',
  });

  function TrainFooterController() {
    const vm = this;
    vm.currentDate = new Date();
  }
})();
