// Module
var code = `${"<" + "script"} type="text/ng-template" id="nodes_renderer.html"> <div ui-tree-handle class="tree-node">
    <a class="btn btn-default btn-xs tree-expand" href="#" ng-show="node.nodes && node.nodes.length > 0" data-nodrag ng-click="node.expanded=!node.expanded">
      <span class="fa " ng-class="{'fa-angle-down': !node.expanded, 'fa-angle-up': node.expanded}"></span>
    </a>
    <span class="checkbox">
      <label>
        <input type="checkbox" name="" value="" ng-model="node.checked" ng-change="vm.onCheckedChanged(node)"/>
        {{node.title}}
      </label>
    </span>
  </div>
  <ul ui-tree-nodes="" ng-model="node.nodes" ng-show="node.expanded">
    <li ng-repeat="node in node.nodes" ui-tree-node ng-include="'nodes_renderer.html'">
    </li>
  </ul> ${"<" + "/script"}> <div ui-tree data-drag-enabled="false" class="tree-view search-filter-control"> <ul class="list-group" ui-tree-nodes ng-model="vm.competencies"> <li class="list-group-item" ng-repeat="node in vm.competencies"> <div ui-tree-node ng-include="'nodes_renderer.html'"></div> </li> </ul> </div> `;
// Exports
export default code;