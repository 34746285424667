(function () {
  angular.module('kmi.lms.course.details').component('courseCommonInfo', {
    bindings: {
      course: '=',
      userCourse: '=',
      hideRating: '=?',
    },
    template: require('ajs/custom_modules/train/course/components/common-info.html').default,
    controller: CourseCommonInfoController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function CourseCommonInfoController($state, currentUser, globalConfig, courseDetailsState, _) {
    var vm = this;

    vm.courseDetailsSettings = globalConfig.settings.courseDetails;

    vm.$onInit = activate;

    function activate() {
      vm.userSettings = currentUser.get();
      vm.description = vm.course.$getHtmlField('description');
      vm.modernFeatures = courseDetailsState.modernFeaturesEnabled(vm.course);
      vm.heroView = _.get(globalConfig, 'settings.courseDetails.heroImageDetails');
    }
  }
})();
