import {
  configureApplicationRuntimeSettings,
  configureApplicationSettings,
} from '../../modules/app/configuration/application-configuration';

require('ajs/vendors');
require('ajs/modules/app/models/class.ts');
require('ajs/global-dependencies.ts');
require('ajs/global-scope.ts');
require('ajs/modules/app/_init.ts');

declare const angular: angular.IAngularStatic;

angular
  .module('elmsSpaApp', [
    'ui.router',
    'ui.router.upgrade',
    'http-auth-interceptor',
    'ui.bootstrap',
    'angular-ladda',
    'ngCookies',
    'ngAnimate',
    'ngResource',
    'ui.mask',
    'ngSanitize',
    'ngAria',
    'globalDependencies',
    'kmi.lms.services',
    'blueimp.fileupload',
    'kmi.lms.core',
    'kmi.lms.components',
    'kmi.lms.train.home',
    'kmi.lms.train.login',
    'kmi.lms.dictionary',
    'kmi.lms.user.certificates',
    'kmi.lms.search',
    'kmi.lms.train.search',
    'kmi.lms.train.user',
    'kmi.lms.train.user.learning',
    'kmi.lms.train.user.registration',
    'kmi.lms.train.user.edit',
    'kmi.lms.quiz',
    'kmi.lms.course.quizzes',
    'kmi.lms.course.common',
    'kmi.lms.course.details',
    'kmi.lms.course.registrations',
    'kmi.lms.user.learning',
    'kmi.lms.user.notifications',
    'kmi.lms.train.trainingPlans',
    'kmi.lms.train.authorization',
    'kmi.lms.user.mail',
    'kmi.lms.train.courseQuality',
    'kmi.lms.course.posts',
    'kmi.lms.passwords',
    'kmi.lms.train.help',
  ])
  .config(configureApplicationSettings)
  .run(configureApplicationRuntimeSettings);

require('ajs/core-modules');
require('ajs/custom_modules/train/_init.ts');
require('ajs/custom_modules/train/app/navigation-component.ts');
require('ajs/custom_modules/train/app/train-policies-component.ts');
require('ajs/custom_modules/train/app/train-footer-component.ts');
require('ajs/custom_modules/train/app/prompt-view-component.ts');
require('ajs/custom_modules/train/portals/_init.ts');
require('ajs/custom_modules/train/authorization/_init.ts');
require('ajs/custom_modules/train/authorization/portal-compliance-component.ts');
require('ajs/custom_modules/train/quiz/_init.ts');
require('ajs/custom_modules/train/course/views/_init.ts');
require('ajs/custom_modules/train/course/quality/_init.ts');
require('ajs/custom_modules/train/course/quality/cdc-quality-sign-directive.ts');
require('ajs/custom_modules/train/course/quality/coures-quality-service.ts');
require('ajs/custom_modules/train/course/components/ce-dates/ce-dates-component.ts');
require('ajs/custom_modules/train/course/components/ce-dates/ce-dates-label-component.ts');
require('ajs/custom_modules/train/course/views/course-details-base-directive.ts');
require('ajs/custom_modules/train/course/views/formats/collection/collection-details-component.ts');
require('ajs/custom_modules/train/course/views/formats/collection/course-list-tree-component.ts');
require('ajs/custom_modules/train/course/views/formats/live-event/live-event-component.ts');
require('ajs/custom_modules/train/course/components/common-info-directive.ts');
require('ajs/custom_modules/train/course/components/course-details-certificates-component.ts');
require('ajs/custom_modules/train/course/components/accreditation-statements/course-accreditation-statements-component.ts');
require('ajs/custom_modules/train/course/components/detailed-info-directive.ts');
// require('ajs/custom_modules/train/events/_init.ts');
require('ajs/custom_modules/train/home/_init.ts');
require('ajs/custom_modules/train/home/train-home-component.ts');
require('ajs/custom_modules/train/home/train-welcome-component.ts');
require('ajs/custom_modules/train/home/welcome/modules/through-this-site-component.ts');
require('ajs/custom_modules/train/home/affiliates-service.ts');
require('ajs/custom_modules/train/login/_init.ts');
// require('ajs/custom_modules/train/login/login-component.ts');
require('ajs/custom_modules/train/login/login-panel-component.ts');
require('ajs/custom_modules/train/training-plans/_init.ts');
require('ajs/custom_modules/train/user/_init.ts');
require('ajs/custom_modules/train/user/registration/_init.ts');
require('ajs/custom_modules/train/user/registration/train-user-registration-component.ts');
require('ajs/custom_modules/train/user/registration/course-provider-survey-prompt.component.ts');
require('ajs/custom_modules/train/user/registration/affiliate-attributes-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/affiliate-group-selection-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/pennsylvania-attributes-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/virginia-attributes-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/kansas-attributes-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/affiliate-custom-attributes-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/account-information-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/mi-account-information-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/wyoming-wdh-attributes-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/group-with-state-selection-directive.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/south-dakota-attributes-component.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/ihsc-attributes-component.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/dhe-attributes-component.ts');
require('ajs/custom_modules/train/user/registration/affiliates/components/groups-with-attributes-selection-component.ts');
require('ajs/custom_modules/train/user/registration/user-attributes-directive.ts');
require('ajs/custom_modules/train/user/registration/registration-wizard-service.ts');
require('ajs/custom_modules/train/user/edit/_init.ts');
require('ajs/custom_modules/train/user/edit/edit-account-component.ts');
require('ajs/custom_modules/train/user/edit/ems/check-ems-account-component.ts');
require('ajs/custom_modules/train/user/edit/modal/leave-noncompliance-portal-component.ts');
require('ajs/custom_modules/train/user/edit/components/edit-account-directive.ts');
require('ajs/custom_modules/train/user/edit/components/edit-email-component.ts');
require('ajs/custom_modules/train/user/edit/components/account-info-directive.ts');
require('ajs/custom_modules/train/user/edit/components/address-info-directive.ts');
require('ajs/custom_modules/train/user/edit/components/contact-info-directive.ts');
require('ajs/custom_modules/train/user/edit/components/groups-info-directive.ts');
require('ajs/custom_modules/train/user/edit/components/organization-info-directive.ts');
require('ajs/custom_modules/train/user/pln/pln-service.ts');
require('ajs/custom_modules/train/user/pln/edit-pln-directive.ts');
require('ajs/custom_modules/train/user/edit/components/pln-directive.ts');
require('ajs/custom_modules/train/user/edit/components/modal/edit-phone-component.ts');
require('ajs/custom_modules/train/user/groups/add-groups-component.ts');
require('ajs/custom_modules/train/user/groups/available-groups-list-directive.ts');
require('ajs/custom_modules/train/user/groups/edit-group-component.ts');
require('ajs/custom_modules/train/user/groups/group-selector-directive.ts');
require('ajs/custom_modules/train/user/groups/self-reg-group-list-directive.ts');
require('ajs/custom_modules/train/user/groups/user-groups-service.ts');
require('ajs/custom_modules/train/user/groups/modal/groups-remove-confirmation-component.ts');
require('ajs/custom_modules/train/user/courses/_init.ts');
require('ajs/custom_modules/train/user/courses/course-record-directive.ts');
require('ajs/custom_modules/train/user/courses/user-courses-directive.ts');
require('ajs/custom_modules/train/user/learning-series/_init.ts');
require('ajs/custom_modules/train/user/learning-series/user-ls-tree-directive.ts');
require('ajs/custom_modules/train/user/learning/_init.ts');
require('ajs/custom_modules/train/user/learning/active-courses-tab-component.ts');
require('ajs/custom_modules/train/user/learning/training-status-tile/directive.ts');
require('ajs/custom_modules/train/user/learning/transcript-tab-component.ts');
require('ajs/custom_modules/train/user/learning/pending-course-surveys-component.ts');
require('ajs/custom_modules/train/user/learning/modal/transcript-download-modal-component.ts');
require('ajs/custom_modules/train/user/learning/competency-assessment-directive.ts');
require('ajs/custom_modules/train/competency/_init.ts');
require('ajs/modules/competency/common/competency-service.ts');
require('ajs/custom_modules/train/competency/competencies-tree-view-directive.ts');
require('ajs/custom_modules/train/search/_init.ts');
require('ajs/custom_modules/train/search/search-filtering-service.ts');
require('ajs/modules/user/notifications/tile/notifications-directive.ts');
require('ajs/modules/user/notifications/notifications-badge-directive.ts');
require('ajs/modules/user/notifications/modal/notifications-alert-component.ts');
require('ajs/modules/user/notifications/modal/notifications-component.ts');
require('ajs/modules/custom-attributes/course/_init.ts');
require('ajs/modules/custom-attributes/course/custom-attributes-service.ts');
require('ajs/modules/course/components/register-user-directive.ts');
require('ajs/custom_modules/train/help/_init.ts');
require('ajs/custom_modules/train/help/help-directory-component.ts');

require('src/projects/train/index.module.ts');
