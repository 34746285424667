(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.edit.directive:trainEditGroupsInfo
   *
   * @description
   * Provides functionality for edit user groups information
   */

  angular.module('kmi.lms.train.user.edit').directive('trainEditGroupsInfo', trainEditGroupsInfo);

  function trainEditGroupsInfo() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
        formOptions: '=',
        groupHelpToggle: '=',
      },
      template: require('ajs/custom_modules/train/user/edit/components/groups-information.html').default,
      controller: TrainEditGroupsInfoController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject*/
  function TrainEditGroupsInfoController(
    $scope,
    $http,
    $q,
    backUrlService,
    trainUserGroupsService,
    Group,
    notificationService,
    portalGroups,
    _,
  ) {
    var vm = this;

    vm.addGroup = addGroup;
    vm.removeGroup = removeGroup;
    vm.findGroupByCode = findGroupByCode;

    activate();

    function activate() {
      $q.all([
        trainUserGroupsService.getDefaultStates().then(function (defaultStates) {
          vm.defaultStates = defaultStates;
        }),

        trainUserGroupsService.getPortals().then(function (topPortals) {
          vm.topPortals = topPortals;
        }),
      ]).then(function () {
        checkExtensibility();
        checkFulfillment();
        vm.ready = true;
      });

      $scope.$watchCollection('vm.user.groups', onUserGroupsChanged);

      // Disable UI controls during http requests
      $scope.$watch(
        function () {
          return $http.pendingRequests.length;
        },
        function () {
          vm.loading = !!$http.pendingRequests.length;
        },
      );
    }

    function onUserGroupsChanged(newGroups, oldGroups) {
      if (!vm.user || angular.equals(newGroups, oldGroups)) {
        return;
      }
      $scope.groupsInformationForm.$setDirty();
      checkExtensibility();
      checkFulfillment();
    }

    /**
     * @description
     * Determines whether portals exists on which user has no selection.
     * User can't select another group when portal already selected.
     */
    function checkExtensibility() {
      vm.canAdd =
        !vm.user.groups.length ||
        _.some(vm.topPortals, function (topGroup) {
          return !_.some(vm.user.groups, function (ug) {
            return (
              (topGroup.id !== portalGroups.state || !nonStatePortalAssignment(ug)) &&
              ug.ileft >= topGroup.ileft &&
              ug.iright <= topGroup.iright &&
              ug.selfRegistrationAllowed
            );
          });
        });
    }

    /**
     * @description
     * Collects a list of groups which are required for selection
     */
    function checkFulfillment() {
      vm.requiredGroupsExist =
        _.some(vm.user.groups, function (item) {
          return item.selfRegistrationAllowed || item.categoryId !== 12;
        }) || null;
    }

    /**
     * @description
     * Redirects to the groups selection subpage. In such case all data in the parent controller remains untouched and
     * we can go back to the previously entered data
     */
    function addGroup() {
      backUrlService.passThroughRedirect('edit.account.groups');
    }

    /**
     * @description
     * Removes provided groups from the list of all user groups (client side only)
     * @param group
     */
    function removeGroup(group) {
      vm.user.groups = _.without(vm.user.groups, group);
    }

    function nonStatePortalAssignment(userGroup) {
      return _.some(vm.topPortals, function (rootGroup) {
        return (
          rootGroup.id !== portalGroups.state &&
          userGroup.selfRegistrationAllowed &&
          userGroup.ileft >= rootGroup.ileft &&
          userGroup.iright <= rootGroup.iright
        );
      });
    }

    function removeDuplicates(items) {
      var result = [];

      _.forEach(items, function (item) {
        var intersection = false;
        _.forEach(vm.user.groups, function (group) {
          if (item.id === group.id) {
            intersection = !intersection;
          }
        });
        if (!intersection) {
          result.push(item);
        }
      });
      return result;
    }

    function findGroupByCode() {
      if (!vm.groupCode) {
        return;
      }

      vm.codeProcessing = Group.query(
        {
          query: {
            corporate_license_code: vm.groupCode,
          },
        },
        function (response) {
          if (response.items.length) {
            // TODO Add to user groups
            vm.groupCode = null;
            var newGroups = removeDuplicates(response.items);
            if (newGroups.length > 0) {
              vm.user.groups = vm.user.groups.concat(newGroups);
            } else {
              notificationService.error('You have already joined this group(s).', 3e3);
            }
          } else {
            notificationService.error("The code you've entered did not match any groups in our system.", 3e3);
          }
        },
      ).$promise.finally(function () {
        vm.codeProcessing = null;
      });
    }
  }
})();
