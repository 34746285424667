(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.registration:affiliateGroupSelection
   *
   * @description
   * State:<affiliate> groups selection
   */
  angular.module('kmi.lms.train.user.registration').directive('affiliateGroupSelection', affiliateGroupSelection);

  /* @ngInject */
  function affiliateGroupSelection() {
    return {
      restrict: 'EA',
      controller: GroupSelectionCtrl,
      controllerAs: 'vm',
      bindToController: true,
      transclude: true,
      scope: {
        user: '=user',
        stateGroup: '=?stateGroup',
        affiliate: '=?affiliate',
        byZipSelection: '@?byZipSelection',
        groupByState: '=?groupByState',
        step: '=?',
      },
      template:
        require('ajs/custom_modules/train/user/registration/affiliates/components/affiliate-group-selection.html')
          .default,
    };
  }

  /* @ngInject */
  function GroupSelectionCtrl(
    $scope,
    $timeout,
    $cacheFactory,
    settingsService,
    trainUserGroupsService,
    portalGroups,
    _,
    TrainRegistrationWizard,
  ) {
    var vm = this,
      wizardCache = $cacheFactory.get('groupSelectionWizard') || $cacheFactory('groupSelectionWizard');

    vm.rootGroups = [
      {
        name: 'State',
        id: portalGroups.state,
        locked: true,
      },
    ];

    vm.accountSettings = settingsService.getSetting(['MyAccount.ExcludedGroups']);

    vm.goBack = goBack;
    vm.$onInit = onInit;

    function onInit() {
      vm.userGroups = [];
      vm.user.groups = [];
      vm.user.groupByState = !!vm.groupByState || null;
      activate();
    }

    function activate() {
      checkForGroupSelection();

      $scope.$on('event:groupSelectionPositionChanged', function (event, value) {
        bindGroupSelectionStep(value);
      });

      $scope.$on('event:selectedGroupsRemoved', function () {
        vm.wizard = null;
      });

      $scope.$on('$destroy', function () {
        if (vm.wizard && vm.wizard.steps.length > 1) {
          wizardCache.put(vm.defaultState.name, vm.wizard);
        }
        if (vm.affiliateGroupsForm) {
          vm.affiliateGroupsForm.$setValidity('userGroups', true);
        }
      });
    }

    function bindGroupSelectionStep(groupsPosition) {
      var stepData;

      if (hasCachedWizard()) {
        restoreWizardFromCache();
        return;
      }

      if (isFinishedGroupSelection(groupsPosition)) {
        vm.wizard.addStep({ name: 'groupSelectionFinished' });
        vm.wizard.nextStep();
        return;
      }

      if (groupsPosition.isRolledBack) {
        rollbackToPosition(groupsPosition);
        return;
      }

      stepData = {
        userGroups: angular.copy(vm.userGroups),
        lastSelectedGroup: groupsPosition.lastSelectedGroup,
        deferredGroups: angular.copy(groupsPosition.deferredGroups),
        name: groupsPosition.lastSelectedGroup.id,
        selectedGroupPath: angular.copy(groupsPosition.selectedGroupPath),
        onEnter: onEnter,
      };

      if (!vm.wizard) {
        vm.wizard = new TrainRegistrationWizard(stepData);
      } else if (vm.wizard.currentStep.name !== groupsPosition.lastSelectedGroup.id) {
        vm.wizard.addStep(stepData);
        vm.wizard.nextStep();
      }
    }

    function addStepToWizard(stepData) {
      if (!vm.wizard) {
        vm.wizard = new TrainRegistrationWizard(stepData);
      } else {
        vm.wizard.addStep(stepData);
      }
    }

    function hasCachedWizard() {
      return vm.defaultState && vm.defaultState.name && wizardCache.get(vm.defaultState.name);
    }

    function restoreWizardFromCache() {
      var cachedWizard = wizardCache.get(vm.defaultState.name);

      wizardCache.put(vm.defaultState.name, null);

      _.each(cachedWizard.steps, function (s) {
        if (s.name !== 'groupSelectionFinished') {
          // populate onEnter from current controllers scope
          s.onEnter = onEnter;
          addStepToWizard(s);
        }
      });

      vm.canChangeStep = true;
      vm.wizard.setStep(vm.wizard.steps[vm.wizard.steps.length - 1]);
    }

    function rollbackToPosition(groupsPosition) {
      var step = _.find(vm.wizard.steps, function (s) {
        return s.name === groupsPosition.lastSelectedGroup.id;
      });

      if (step) {
        vm.canChangeStep = true;
        vm.wizard.setStep(step);
      }
    }

    function isFinishedGroupSelection(state) {
      return state.lastSelectedGroup === null && !state.deferredGroups.length && !state.selectedGroupPath.length;
    }

    function goBack() {
      vm.canChangeStep = true;
      vm.wizard.prevStep();
    }

    /**
     * @description
     * Remove all steps after current and assign saved group selection position models
     */
    function onEnter() {
      vm.step = !vm.wizard || vm.wizard.currentStep.isFirstStep ? null : vm.wizard.currentStep.name;

      // We should execute onEnter only for prevStep transition
      if (!vm.canChangeStep) {
        return;
      }

      vm.canChangeStep = null;

      var i = vm.wizard.getStepsCount() - 1;
      while (vm.wizard.currentStep.name !== vm.wizard.steps[i].name) {
        vm.wizard.removeStep(vm.wizard.steps[i]);
        i--;
      }

      vm.userGroups = angular.copy(vm.wizard.currentStep.userGroups);
      vm.lastSelectedGroup = vm.wizard.currentStep.lastSelectedGroup;
      vm.selectedGroupPath = angular.copy(vm.wizard.currentStep.selectedGroupPath);
      vm.deferredGroups = angular.copy(vm.wizard.currentStep.deferredGroups);
    }

    function initGroupSelection() {
      vm.groupComponentLoaded = false;

      // init models for state group selection (get default state if state group not passed to directive)
      if (vm.stateGroup) {
        $timeout(function () {
          initGroupSelectionModels(vm.stateGroup);
        });
      } else if (!!vm.affiliate && !vm.byZipSelection) {
        trainUserGroupsService.getChildGroups(portalGroups.state).then(function (groups) {
          var stateGroup = _.filter(groups.items || [], function (g) {
            return _.trim(g.name.toLowerCase()) === vm.affiliate;
          });

          initGroupSelectionModels(stateGroup);
        });
      } else {
        trainUserGroupsService.getDefaultStates().then(function (states) {
          initGroupSelectionModels(states);
        });
      }
    }

    function initGroupSelectionModels(states) {
      vm.defaultState = _.head(states);
      angular.extend(vm.defaultState, {
        locked: true,
        selfRegistrationAllowed: false,
      });
      vm.lastSelectedGroup = vm.defaultState;
      vm.selectedGroupPath = [vm.rootGroups[0]];

      vm.groupComponentLoaded = true;
      checkGroupSelectionInProgress();
    }

    function checkForGroupSelection() {
      $scope.$watch('vm.userGroups', setGroups, true);
      $scope.$watch('vm.lastSelectedGroup', checkGroupSelectionInProgress, true);
    }

    function setGroups() {
      if (vm.userGroups.length) {
        angular.extend(vm.user.groups, vm.userGroups);
        vm.affiliateGroupsForm.$setValidity('userGroups', true);
      } else {
        vm.user.groups = [];

        if (!vm.wizard) {
          initGroupSelection();
        }
      }
    }

    function checkGroupSelectionInProgress() {
      if (vm.affiliateGroupsForm) {
        vm.affiliateGroupsForm.$setValidity(
          'userGroups',
          !vm.groupComponentLoaded || vm.lastSelectedGroup ? false : true,
        );
      }
    }
  }
})();
