(function () {
  angular
    .module('kmi.lms.train.user')
    .component('trainUserGroupsRemoveConfirmationModal', {
      template: require('ajs/custom_modules/train/user/groups/modal/groups-remove-confirmation.html').default,
      controller: TrainUserGroupsRemoveConfirmationController,
      controllerAs: 'vm',
      bindings: {
        modalInstance: '<',
        resolve: '<',
      },
    })
    .component('trainUserGroupsRemoveAffiliatesConfirmationModal', {
      template: require('ajs/custom_modules/train/user/groups/modal/groups-affiliates-remove-confirmation.html')
        .default,
      controller: TrainUserGroupsRemoveConfirmationController,
      controllerAs: 'vm',
      bindings: {
        modalInstance: '<',
        resolve: '<',
      },
    });

  /* @ngInject */
  function TrainUserGroupsRemoveConfirmationController() {
    var vm = this;

    vm.submit = submit;
    vm.cancel = cancel;
    vm.$onInit = onInit;

    function onInit() {
      vm.affiliatePath = vm.resolve.affiliatePath;
    }

    function submit() {
      vm.modalInstance.close();
    }

    function cancel() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
