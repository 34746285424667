(function () {
  angular.module('kmi.lms.train.user').factory('userPlnService', userPlnService);

  /* @ngInject */
  function userPlnService($http) {
    return {
      getTypes: getTypes,
      getGroups: getGroups,
    };

    /**
     * @description
     * Fetches Professional license number types from WS
     * @returns {*|{finally}|IPromise<TResult>}
     */
    function getTypes() {
      return $http.get('/a/user/professional_license_number/types/', { cache: true }).then(function (response) {
        return response.data;
      });
    }

    /**
     * @description
     * Fetches Professional license number groups from WS
     * @returns {*|{finally}|IPromise<TResult>}
     */
    function getGroups() {
      return $http.get('/a/user/professional_license_number/groups/', { cache: true }).then(function (response) {
        return response.data;
      });
    }
  }
})();
