(function () {
  angular.module('kmi.lms.train.user').component('editUserPln', {
    template: require('ajs/custom_modules/train/user/pln/edit-pln.html').default,
    controller: EditUserPlnController,
    controllerAs: 'vm',
    bindings: {
      licence: '=?',
      user: '=',
      cancelHandler: '&onCancel',
      saveHandler: '&onSave',
      deleteHandler: '&onDelete',
    },
  });

  /* @ngInject */
  function EditUserPlnController($scope, userPlnService, _) {
    var vm = this;

    vm.licenceNumberPattern = /^\d{10}$/;
    vm.save = save;
    vm.cancel = cancel;
    vm.deleteLicence = deleteLicence;
    vm.filterLicenceTypes = filterLicenceTypes;
    vm.$onInit = onInit;

    function onInit() {
      vm.licenceDraft = _.merge({}, vm.licence);
      activate();
    }

    function activate() {
      userPlnService.getTypes().then(function (types) {
        vm.types = types;

        bindData();

        $scope.$watchGroup(['vm.licenceType', 'vm.licenceSubType'], function () {
          vm.licenceDraft.type = _.omit(vm.licenceType, 'items');
          vm.licenceDraft.subType = _.omit(vm.licenceSubType, 'items');
        });
      });
    }

    /**
     * @description
     * Find licence type and sub type in the fetched types list
     */
    function bindData() {
      if (_.get(vm.licenceDraft, 'type.id')) {
        vm.licenceType = _.find(vm.types, function (item) {
          return vm.licenceDraft.type.id === item.id;
        });
      }

      if (vm.licenceType && _.get(vm.licenceDraft, 'subType.id')) {
        vm.licenceSubType = _.find(vm.licenceType.items, function (item) {
          return vm.licenceDraft.subType.id === item.id;
        });
      }
    }

    /**
     * @description
     * Triggers outer save handler and pass licenceData to save.
     */
    function save() {
      var licenceData;

      vm.submitted = true;

      if (vm.editPlnForm.$valid) {
        licenceData = angular.extend(angular.copy(vm.licenceDraft), {});

        vm.saveHandler({ licenceData: licenceData });
      }
    }

    /**
     * @description
     * Triggers outer cancel handler
     */
    function cancel() {
      vm.cancelHandler();
    }

    /**
     * @description
     * Triggers outer delete handler
     */
    function deleteLicence() {
      if (vm.licence) {
        vm.deleteHandler({ licence: vm.licence });
      }
    }

    /**
     * @description
     * Filters licences which has already been selected
     * @param licenceType
     * @returns {boolean}
     */
    function filterLicenceTypes(licenceType) {
      return !_.some(vm.user.licences, function (licence) {
        return licenceType.id === licence.type.id && (!vm.licence || vm.licence !== licence);
      });
    }
  }
})();
