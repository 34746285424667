(function () {
  /**
   * @ngdoc component
   * @name kmi.lms.train.user.edit.component:trainEditPhoneModal
   * @description
   * Provides functionality for add/edit user phone
   * @requires $uibModalInstance
   * @requires phoneTypes
   * @requires userCountry
   * @requires usPhoneMask
   * @requires mode
   * @requires phone
   * @requires remainingPhoneTypes
   */

  angular.module('kmi.lms.train.user.edit').component('trainEditPhoneModal', {
    template: require('ajs/custom_modules/train/user/edit/components/modal/edit-phone.html').default,
    controller: TrainEditPhoneModalCtrl,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  /* @ngInject */
  function TrainEditPhoneModalCtrl(phoneTypes, usPhoneMask) {
    var vm = this;

    vm.remainingPhoneTypes = [];
    vm.workPhoneType = phoneTypes.work;
    vm.phoneMask = usPhoneMask;

    vm.ok = ok;
    vm.closeModal = closeModal;
    vm.remove = remove;
    vm.$onInit = onInit;

    function onInit() {
      vm.mode = vm.resolve.mode;
      vm.phone = {};
      vm.remainingPhoneTypes = angular.copy(vm.resolve.remainingPhoneTypes);

      if (vm.mode === 'edit') {
        vm.phone = angular.copy(vm.resolve.phone);
        vm.remainingPhoneTypes.push(vm.resolve.phone.type);
      }

      if (vm.resolve.userCountry !== 'US') {
        vm.phoneMask = '';
      }
    }

    function ok() {
      if (vm.phone.type !== phoneTypes.work) {
        vm.phone.extension = null;
      }

      vm.modalInstance.close({
        mode: vm.mode,
        phone: vm.phone,
      });
    }

    function remove() {
      vm.modalInstance.close({
        mode: 'remove',
        phone: vm.phone,
      });
    }

    function closeModal() {
      vm.modalInstance.dismiss();
    }
  }
})();
