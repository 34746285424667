(function () {
  angular.module('kmi.lms.train.user.learning').component('trainTrainingStatusTile', {
    template: require('ajs/custom_modules/train/user/learning/training-status-tile/template.html').default,
    controller: TrainTrainingStatusTileController,
    controllerAs: 'vm',
    bindToController: true,
  });

  /* @ngInject */
  function TrainTrainingStatusTileController(
    $q,
    $http,
    apiUrl,
    userCoursesService,
    currentUser,
    serverErrorHandlerService,
  ) {
    var vm = this;

    activate();

    //////////

    function activate() {
      $q.all([
        userCoursesService
          .getSummary({
            include_inactive_courses: true,
            include_external: true,
          })
          .then(function (res) {
            angular.extend(vm, res);
            vm.coursesNotCompleted = vm.coursesInProgress + vm.coursesNotStarted;
          }),
        $http.get(apiUrl('/a/user/' + currentUser.get().id + '/learning_series/summary/')).then(function (res) {
          vm.trainingPlansCompleted = res.data.completed;
          vm.trainingPlansInProgress = res.data.inProgress;
        }),
      ]).then(
        function () {
          vm.loaded = true;
        },
        function (reason) {
          serverErrorHandlerService
            .handleForbiddenError(reason, function (data) {
              vm.error = data;
            })
            .then(null, angular.noop);
        },
      );
    }
  }
})();
