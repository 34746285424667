(function () {
  angular.module('kmi.lms.train.courseQuality').component('cdcQualitySign', {
    template: require('ajs/custom_modules/train/course/quality/cdc-quality-sign.html').default,
    controller: CdcQualitySignController,
    controllerAs: 'vm',
    bindings: {
      course: '<?',
      appearance: '@?',
    },
  });

  /* @ngInject */
  function CdcQualitySignController(courseQualityService) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.appearance = vm.appearance || 'label';
      activate();
    }

    function activate() {
      if (vm.course) {
        vm.meetQuality = false;

        courseQualityService.checkCdcQuality(vm.course.id).then(function (meetQuality) {
          vm.meetQuality = meetQuality;
        });
      } else {
        vm.meetQuality = true;
      }
    }
  }
})();
