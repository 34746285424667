(function () {
  angular.module('kmi.lms.train.user.courses').component('trainUserCourses', {
    template: require('ajs/custom_modules/train/user/courses/user-courses.html').default,
    controller: UserCoursesController,
    controllerAs: 'vm',
    bindings: {
      columns: '=',
      filters: '=',
      statusSet: '=',
      highestResult: '=?',
      recordDataSource: '=?',
      showRemoveAction: '=?',
      sortingEnabled: '=',
      sorting: '=?',
    },
  });

  /* @ngInject */
  function UserCoursesController(
    $scope,
    $location,
    UserCourse,
    currentUser,
    sjcl,
    _,
    courseRegistrationStatus,
    globalConfig,
  ) {
    var vm = this,
      cacheKey;

    vm.sortOrder = sortOrder;
    vm.isCurrentSortPredicate = isCurrentSortPredicate;
    vm.$onInit = onInit;
    vm.getStatusIdByUserCourse = courseRegistrationStatus.getStatusIdByUserCourse;
    vm.globalCourseRegistrationSettings = globalConfig.courseRegistration || {};

    function onInit() {
      vm.userId = currentUser.get().id;
      vm.predicate = _.get(vm.sorting, 'field') || 'course.name';
      vm.reverse = vm.predicate === 'course.name' ? false : _.get(vm.sorting, 'dir') === 'desc';

      // Generate cache key depending on the filters
      cacheKey = [
        'userCourses:',
        vm.userId,
        sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits(angular.toJson(vm.filters))),
      ].join('');

      // Bind cached data until the data from the server will not come
      bindCachedData();
      loadData();
      updateScores();
      rebindCreditTypes();

      // Remove record from the courses list when it has been withdrowed
      $scope.$on('event:userCourseRecord.removed', function (event, record) {
        vm.courses = _.without(vm.courses, record);
        refreshCache();
      });

      // Reload courses from WS when some course registration data changed
      $scope.$on('event:course.registration.action.finished', function () {
        loadData();
      });

      $scope.$watch('vm.loadingPromise', function () {
        // Send event to the parent controller
        $scope.$emit('event:dataLoading', !!vm.loadingPromise);
      });

      $scope.$watch('vm.statusSet', filterStatuses);
    }

    function filterStatuses() {
      if (vm.courses && vm.courses.length) {
        for (var i = vm.courses.length - 1; i >= 0; i--) {
          var course = vm.courses[i];
          course.filtered = filterByStatus(course);
        }
      }
    }

    function filterByStatus(node) {
      var status = setStatus(node);
      return status && (!vm.statusSet || vm.statusSet.indexOf(status) >= 0);
    }

    function setStatus(node) {
      var status;
      if (node.type === 'externalRecord') {
        status = 5;
      } else if (!node.type || node.type === 'course') {
        status = vm.getStatusIdByUserCourse(node, vm.highestResult, vm.recordDataSource);
      }
      return status;
    }

    function loadData() {
      const query = {
        userId: vm.userId,
      };

      angular.extend(query, vm.filters);

      vm.loadingPromise = UserCourse.query(query)
        .$promise.then(function (response) {
          vm.courses = response.courses;

          filterStatuses();
          refreshCache();
          rebindCreditTypes();
        })
        .finally(function () {
          // Unlock UI
          vm.loadingPromise = null;
          updateScores();
        });
    }

    function refreshCache() {
      sessionStorage.setItem(cacheKey, JSON.stringify(vm.courses));
    }

    function bindCachedData() {
      var cachedModel = JSON.parse(sessionStorage.getItem(cacheKey));
      if (cachedModel) {
        vm.courses = cachedModel;
      }
    }

    function updateScores() {
      if (vm.courses) {
        for (var i = 0; i < vm.courses.length; i++) {
          vm.courses[i].gradePoints =
            vm.courses[i].gradePoints === null || vm.courses[i].gradePoints === undefined
              ? _.get(vm.courses[i], 'lastCompletedRegistration.courseGradePoints', null)
              : vm.courses[i].gradePoints || null;
          vm.courses[i].gradePercentage =
            vm.courses[i].gradePercentage === null || vm.courses[i].gradePercentage === undefined
              ? _.get(vm.courses[i], 'lastCompletedRegistration.courseGradePercentage', null)
              : vm.courses[i].gradePercentage || null;
        }
      }
    }

    function sortOrder(predicate) {
      vm.reverse = _.isEqual(vm.predicate, predicate) ? !vm.reverse : false;
      vm.predicate = predicate;

      vm.sorting = {
        field: predicate,
        dir: vm.reverse ? 'desc' : 'asc',
      };

      var searchQuery = $location.search();
      if (searchQuery.filter) {
        delete searchQuery.filter;
        delete searchQuery.dir;
      }
      angular.extend(searchQuery, { filter: vm.predicate, dir: vm.reverse ? 'desc' : 'asc' });

      $location.search(searchQuery);
    }

    function isCurrentSortPredicate(predicate) {
      return _.isEqual(vm.predicate, predicate);
    }

    function rebindCreditTypes() {
      _.map(vm.courses, function (course) {
        course.creditAmount = _.get(course, 'creditTypes.0.amount', course.creditAmount);
      });
    }
  }
})();
