(function () {
  angular.module('kmi.lms.train.login').component('trainLoginPanel', {
    template: require('ajs/custom_modules/train/login/login-panel.html').default,
    controller: TrainLoginPanelController,
    controllerAs: 'vm',
    bindings: {
      hideBecomeCourseProvider: '<?',
    },
  });

  function TrainLoginPanelController(siteSettingService) {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.currentSiteSetting = siteSettingService.getSiteSetting();
    }
  }
})();
