(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.edit.directive:trainEditContactInfo
   *
   * @description
   * Provides functionality for edit user contact information
   */

  angular.module('kmi.lms.train.user.edit').directive('trainEditContactInfo', trainEditContactInfo);

  function trainEditContactInfo() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
        formOptions: '=',
        view: '@?',
      },
      template: require('ajs/custom_modules/train/user/edit/components/contact-information.html').default,
      controller: TrainEditContactInfoController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject*/
  function TrainEditContactInfoController($scope, $uibModal, phoneTypes, _) {
    var vm = this;

    vm.phones = [];
    vm.remainingPhoneTypes = [];
    vm.phoneNumbers = [];

    vm.editPhone = editPhone;
    vm.removePhone = removePhone;
    vm.$onInit = activate;

    function activate() {
      vm.remainingPhoneTypes = _.toArray(phoneTypes);

      $scope.$watchCollection('vm.phones', updatePhones);

      if (vm.user.phone) {
        vm.phones.push({ type: phoneTypes.work, number: vm.user.phone, extension: vm.user.extension });
      }
      if (vm.user.eveningPhone) {
        vm.phones.push({ type: phoneTypes.home, number: vm.user.eveningPhone });
      }
      if (vm.user.mobile) {
        vm.phones.push({ type: phoneTypes.mobile, number: vm.user.mobile });
      }

      vm.phoneNumbers = _.map(vm.phones, 'number');
    }

    function editPhone(mode, phone) {
      var modalInstance = $uibModal.open({
        component: 'trainEditPhoneModal',
        resolve: {
          mode: function () {
            return mode;
          },
          phone: function () {
            return phone;
          },
          remainingPhoneTypes: function () {
            return vm.remainingPhoneTypes;
          },
          userCountry: function () {
            return vm.user.country;
          },
        },
      });

      modalInstance.result.then(function (result) {
        switch (result.mode) {
          case 'add':
            vm.phones.push(result.phone);
            break;
          case 'edit':
            vm.phones[_.indexOf(vm.phones, phone)] = result.phone;
            break;
          case 'remove':
            removePhone(result.phone);
            break;
        }
      });
    }

    function removePhone(phone) {
      _.remove(vm.phones, function (p) {
        return p.type === phone.type && p.number === phone.number;
      });
    }

    function updatePhones(updatedPhones) {
      var work, home, mobile;

      if (!vm.user) {
        return;
      }

      vm.phoneNumbers = _.map(updatedPhones, 'number');

      work = _.find(updatedPhones, function (phone) {
        return phone.type === phoneTypes.work;
      });
      home = _.find(updatedPhones, function (phone) {
        return phone.type === phoneTypes.home;
      });
      mobile = _.find(updatedPhones, function (phone) {
        return phone.type === phoneTypes.mobile;
      });

      if (work) {
        vm.user.phone = work.number;
        vm.user.extension = work.extension;
        vm.remainingPhoneTypes = _.without(vm.remainingPhoneTypes, work.type);
      } else if (vm.user.phone || vm.user.extension) {
        vm.user.phone = vm.user.extension = null;
        vm.remainingPhoneTypes.push(phoneTypes.work);
      }
      if (home) {
        vm.user.eveningPhone = home.number;
        vm.remainingPhoneTypes = _.without(vm.remainingPhoneTypes, home.type);
      } else if (vm.user.eveningPhone) {
        vm.user.eveningPhone = null;
        vm.remainingPhoneTypes.push(phoneTypes.home);
      }
      if (mobile) {
        vm.user.mobile = mobile.number;
        vm.remainingPhoneTypes = _.without(vm.remainingPhoneTypes, mobile.type);
      } else if (vm.user.mobile) {
        vm.user.mobile = null;
        vm.remainingPhoneTypes.push(phoneTypes.mobile);
      }
    }
  }
})();
