import angular from 'angular';
import { IBlogPost } from '../../../../src/app/modules/blog/models/blog-post.model';

(function () {
  /**
   * @ngdoc component
   * @name kmi.lms.train.home.component:trainWelcomeComponent
   * @description
   * Provides welcome services for anonymous users
   * @requires $window
   * @requires affiliatesService
   *
   */
  angular
    .module('kmi.lms.train.home')
    .component('trainWelcomeComponent', {
      template: function ($templateCache, globalConfig, _) {
        var portalTemplatePath = [
          'custom_modules/train/home/welcome/' + _.trim(globalConfig.base, '/').toLowerCase(),
          '.html',
        ].join('');
        var template = $templateCache.get(portalTemplatePath);

        return template || $templateCache.get('custom_modules/train/home/welcome/welcome.html');
      },
      controller: WelcomeController,
      controllerAs: 'vm',
    })
    .run(moduleRun);

  function moduleRun($templateCache) {
    $templateCache.put(
      'custom_modules/train/home/welcome/adh.html',
      require('ajs/custom_modules/train/home/welcome/adh.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/arizona.html',
      require('ajs/custom_modules/train/home/welcome/arizona.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/cdctrain.html',
      require('ajs/custom_modules/train/home/welcome/cdctrain.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/cdphtrain.html',
      require('ajs/custom_modules/train/home/welcome/cdphtrain.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/colorado.html',
      require('ajs/custom_modules/train/home/welcome/colorado.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/connecticut.html',
      require('ajs/custom_modules/train/home/welcome/connecticut.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/curated.html',
      require('ajs/custom_modules/train/home/welcome/curated.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/detrain.html',
      require('ajs/custom_modules/train/home/welcome/detrain.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/dhe.html',
      require('ajs/custom_modules/train/home/welcome/dhe.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/fda.html',
      require('ajs/custom_modules/train/home/welcome/fda.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/florida.html',
      require('ajs/custom_modules/train/home/welcome/florida.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/georgia.html',
      require('ajs/custom_modules/train/home/welcome/georgia.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/hawaii.html',
      require('ajs/custom_modules/train/home/welcome/hawaii.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/idaho.html',
      require('ajs/custom_modules/train/home/welcome/idaho.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/ihsctrain.html',
      require('ajs/custom_modules/train/home/welcome/ihsctrain.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/illinois.html',
      require('ajs/custom_modules/train/home/welcome/illinois.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/inclusivecare.html',
      require('ajs/custom_modules/train/home/welcome/inclusivecare.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/indiana.html',
      require('ajs/custom_modules/train/home/welcome/indiana.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/ks.html',
      require('ajs/custom_modules/train/home/welcome/ks.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/ky.html',
      require('ajs/custom_modules/train/home/welcome/ky.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/maryland.html',
      require('ajs/custom_modules/train/home/welcome/maryland.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/ma.html',
      require('ajs/custom_modules/train/home/welcome/ma.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/medlearning.html',
      require('ajs/custom_modules/train/home/welcome/medlearning.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/mi-train.html',
      require('ajs/custom_modules/train/home/welcome/mi-train.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/mn.html',
      require('ajs/custom_modules/train/home/welcome/mn.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/mt.html',
      require('ajs/custom_modules/train/home/welcome/mt.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/mrc.html',
      require('ajs/custom_modules/train/home/welcome/mrc.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/nd.html',
      require('ajs/custom_modules/train/home/welcome/nd.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/nm.html',
      require('ajs/custom_modules/train/home/welcome/nm.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/odh.html',
      require('ajs/custom_modules/train/home/welcome/odh.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/ok.html',
      require('ajs/custom_modules/train/home/welcome/ok.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/pa.html',
      require('ajs/custom_modules/train/home/welcome/pa.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/rhodeisland.html',
      require('ajs/custom_modules/train/home/welcome/rhodeisland.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/sccphd.html',
      require('ajs/custom_modules/train/home/welcome/sccphd.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/sd.html',
      require('ajs/custom_modules/train/home/welcome/sd.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/texas.html',
      require('ajs/custom_modules/train/home/welcome/texas.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/tn.html',
      require('ajs/custom_modules/train/home/welcome/tn.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/utah.html',
      require('ajs/custom_modules/train/home/welcome/utah.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/vbamde.html',
      require('ajs/custom_modules/train/home/welcome/vbamde.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/vha.html',
      require('ajs/custom_modules/train/home/welcome/vha.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/virginia.html',
      require('ajs/custom_modules/train/home/welcome/virginia.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/washington.html',
      require('ajs/custom_modules/train/home/welcome/washington.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/welcome.html',
      require('ajs/custom_modules/train/home/welcome/welcome.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/wellaheadla.html',
      require('ajs/custom_modules/train/home/welcome/wellaheadla.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/wi.html',
      require('ajs/custom_modules/train/home/welcome/wi.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/wv.html',
      require('ajs/custom_modules/train/home/welcome/wv.html').default,
    );
    $templateCache.put(
      'custom_modules/train/home/welcome/wyoming.html',
      require('ajs/custom_modules/train/home/welcome/wyoming.html').default,
    );
  }

  /* @ngInject */
  function WelcomeController(
    $scope,
    $window,
    affiliatesService,
    portalsService,
    siteSettingService,
    samlSsoService,
    globalConfig,
    _,
  ) {
    var vm = this;

    vm.selectedAffiliate = null;
    vm.siteSettings = siteSettingService.getSiteSetting();

    vm.goToAffiliate = goToAffiliate;
    vm.setSamlLoginGroup = setSamlLoginGroup;

    activate();

    function activate() {
      affiliatesService.get().then(function (data) {
        vm.affiliates = data;
      });

      $scope.$on('elmsAuthService.notification.login', function () {
        vm.hideLeftPanel = true;
      });

      vm.defaultPosts = _getDefaultPosts();
    }

    function setSamlLoginGroup(groupId) {
      if (angular.isDefined(groupId)) {
        samlSsoService
          .checkGroupAvailability(groupId)
          .toPromise()
          .then(function (data) {
            var template = _.template('/a/integration/saml_sso/{{groupId}}/request_idp_auth/');
            if (globalConfig.iamIdpLoginLinks && globalConfig.iamIdpLoginLinks[groupId]) {
              template = _.template(globalConfig.iamIdpLoginLinks[groupId]);
            }
            vm.samlLoginLink = template({ groupId: groupId });
            vm.samlLoginEnabled = data.available;
          });
      }
    }

    function goToAffiliate() {
      if (!vm.selectedAffiliate) {
        return;
      }

      $window.location.href = portalsService.getPortalPath(vm.selectedAffiliate);
    }

    function _getDefaultPosts(): IBlogPost[] {
      return <IBlogPost[]>[
        {
          id: 0,
          title: '',
          notify: false,
          active: false,
          channel: {
            id: 2,
            name: 'Hot Topics',
          },
          channelId: 2,
          content:
            '<a href="search?query=%22self-care%22%20%22stress%22%20%22mindfulness%22%20%22meditation%22&type=course" title="Self-Care">\n' +
            '              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M163.9 136.9c-29.4-29.8-29.4-78.2 0-108s77-29.8 106.4 0l17.7 18 17.7-18c29.4-29.8 77-29.8 106.4 0s29.4 78.2 0 108L310.5 240.1c-6.2 6.3-14.3 9.4-22.5 9.4s-16.3-3.1-22.5-9.4L163.9 136.9zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5L192 512 32 512c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l36.8 0 44.9-36c22.7-18.2 50.9-28 80-28l78.3 0 16 0 64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0-16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l120.6 0 119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384c0 0 0 0 0 0l-.9 0c.3 0 .6 0 .9 0z"/></svg>Self-Care\n' +
            '            </a>',
          createdDate: '',
          expirationDate: '',
          groups: null,
          heroImageId: null,
          permissions: [],
          seoDescription: null,
          showAuthorInfo: false,
          showTitle: false,
          tags: [],
          updatedDate: '',
          moduleId: 1,
        },
        {
          id: 0,
          title: '',
          notify: false,
          active: false,
          channel: {
            id: 2,
            name: 'Hot Topics',
          },
          channelId: 2,
          content:
            '<a href="https://services.phf.org/data_science_training/" target="_blank" title="Data Science / Informatics">\n' +
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zm-312 8l0 64c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80-96l0 160c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80 64l0 96c0 13.3 10.7 24 24 24s24-10.7 24-24l0-96c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>Data Science / Informatics\n' +
            '</a>',
          createdDate: '',
          expirationDate: '',
          groups: null,
          heroImageId: null,
          permissions: [],
          seoDescription: null,
          showAuthorInfo: false,
          showTitle: false,
          tags: [],
          updatedDate: '',
          moduleId: 1,
        },
        {
          id: 0,
          title: '',
          notify: false,
          active: false,
          channel: {
            id: 2,
            name: 'Hot Topics',
          },
          channelId: 2,
          content:
            '<a href="search?query=%22std%22%20%22stds%22%20%22sti%22%20%22stis%22%20%22syphilis%22&sort=newest&type=course" title="STDs / STIs">' +
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l32 0L96 32 64 32zm64 0l0 448 320 0 0-448L128 32zM512 480c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64l-32 0 0 448 32 0zM256 176c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 48 48 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-48 0 0 48c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-48-48 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l48 0 0-48z"/></svg>STDs / STIs' +
            '</a>',
          createdDate: '',
          expirationDate: '',
          groups: null,
          heroImageId: null,
          permissions: [],
          seoDescription: null,
          showAuthorInfo: false,
          showTitle: false,
          tags: [],
          updatedDate: '',
          moduleId: 1,
        },
        {
          id: 0,
          title: '',
          notify: false,
          active: false,
          channel: {
            id: 2,
            name: 'Hot Topics',
          },
          channelId: 2,
          content:
            '<a href="search?query=%22processed%20food%22%20%22nutrition%22%20%2B(-1041032)&type=course" title="Processed Food / Nutrition">\n' +
            '    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 0C400 0 288 32 288 176l0 112c0 35.3 28.7 64 64 64l32 0 0 128c0 17.7 14.3 32 32 32s32-14.3 32-32l0-128 0-112 0-208c0-17.7-14.3-32-32-32zM64 16C64 7.8 57.9 1 49.7 .1S34.2 4.6 32.4 12.5L2.1 148.8C.7 155.1 0 161.5 0 167.9c0 45.9 35.1 83.6 80 87.7L80 480c0 17.7 14.3 32 32 32s32-14.3 32-32l0-224.4c44.9-4.1 80-41.8 80-87.7c0-6.4-.7-12.8-2.1-19.1L191.6 12.5c-1.8-8-9.3-13.3-17.4-12.4S160 7.8 160 16l0 134.2c0 5.4-4.4 9.8-9.8 9.8c-5.1 0-9.3-3.9-9.8-9L127.9 14.6C127.2 6.3 120.3 0 112 0s-15.2 6.3-15.9 14.6L83.7 151c-.5 5.1-4.7 9-9.8 9c-5.4 0-9.8-4.4-9.8-9.8L64 16zm48.3 152l-.3 0-.3 0 .3-.7 .3 .7z"/></svg>Processed Food / Nutrition\n' +
            '  </a>',
          createdDate: '',
          expirationDate: '',
          groups: null,
          heroImageId: null,
          permissions: [],
          seoDescription: null,
          showAuthorInfo: false,
          showTitle: false,
          tags: [],
          updatedDate: '',
          moduleId: 1,
        },
      ];
    }
  }
})();
