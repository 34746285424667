(function () {
  angular.module('kmi.lms.train.user.learning').component('trainTranscriptModal', {
    template: require('ajs/custom_modules/train/user/learning/modal/transcript-download-modal.html').default,
    controller: TrainTranscriptModalCtrl,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  /* @ngInject */
  function TrainTranscriptModalCtrl(userCoursesService, currentUser, moment) {
    var vm = this;
    var presetDate = new Date();
    presetDate.setDate(presetDate.getDate() - 30);

    vm.closeModal = closeModal;
    vm.format = 'mediumDate';
    vm.startDate = { date: presetDate, opened: false };
    vm.endDate = { date: new Date(), opened: false };

    vm.openStartPopup = openStartPopup;
    vm.openEndPopup = openEndPopup;
    vm.downloadTranscript = downloadTranscript;

    function downloadTranscript() {
      userCoursesService.exportRegistrations(currentUser.get().id, {
        data: {
          statuses: vm.resolve.statuses,
          terminal_status: true,
          include_external: true,
          creditsAwarded: true,
          completionDateStart: vm.startDate.date ? moment(vm.startDate.date).format('YYYY-MM-DD') : null,
          completionDateEnd: vm.endDate.date ? moment(vm.endDate.date).format('YYYY-MM-DD') : null,
        },
        sort: vm.resolve.sorting,
        fileName: buildFileName(),
        format: 'pdf',
      });

      closeModal();

      function buildFileName() {
        var timeStamp: any = [];
        if (vm.startDate.date) {
          timeStamp.push(moment(vm.startDate.date).format('YYYY-MM-DD'));
        }
        if (vm.endDate.date) {
          timeStamp.push(moment(vm.endDate.date).format('YYYY-MM-DD'));
        }

        if (!timeStamp.length) {
          timeStamp.push(moment(new Date()).format('YYYY-MM-DD'));
        }

        timeStamp = timeStamp.join('_');
        return ['transcript_', timeStamp].join('');
      }
    }

    function openStartPopup() {
      vm.startDate.opened = true;
    }

    function openEndPopup() {
      vm.endDate.opened = true;
    }

    function closeModal() {
      vm.modalInstance.close();
    }
  }
})();
