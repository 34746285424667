(function () {
  angular.module('kmi.lms.train.trainingPlans', ['kmi.lms.learningSeries']).config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider) {
    /* @ngInject */
    function lsResolver($transition$, LearningSeries) {
      return LearningSeries.get({ lsId: $transition$.params().id }).$promise;
    }

    $stateProvider.state('main.trainingPlan', {
      url: '^/training_plan/{id}',
      component: 'learningSeriesViewComponent',
      data: {
        label: 'Training plan',
        availableFor: ['anonymous', 'regularUser', 'admin'],
      },
      params: {
        id: null,
      },
      resolve: { ls: lsResolver },
    });
  }
})();
