(function () {
  angular.module('kmi.lms.train.user.learningSeries').component('userLearningSeriesTree', {
    template: require('ajs/custom_modules/train/user/learning-series/user-ls-tree.html').default,
    controller: UserLearningSeriesTreeController,
    controllerAs: 'vm',
    bindings: {
      userId: '=',
    },
  });

  /* @ngInject */
  function UserLearningSeriesTreeController($scope, UserLearningSeries, learningSeriesService, _) {
    var vm = this;

    vm.columns = {
      status: true,
      targetCompletionDate: true,
      proofOfCompletion: true,
    };

    vm.$onInit = activate;

    function activate() {
      loadData().then(function () {
        return learningSeriesService.populateLearningSeriesUserProgress(vm.userId, vm.courses);
      });
    }

    function loadData() {
      vm.loadingPromise = true;

      return UserLearningSeries.query({ userId: vm.userId })
        .$promise.then(function (userLearningSeries) {
          vm.courses = _.map(userLearningSeries.items, function (record) {
            record.type = 'learning_series';
            return record;
          });
        })
        .finally(function () {
          // Unlock UI
          vm.loadingPromise = null;
        });
    }
  }
})();
