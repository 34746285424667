(function () {
  angular.module('kmi.lms.train.user.edit').component('trainEditEmail', {
    template: require('ajs/custom_modules/train/user/edit/components/edit-email.html').default,
    controller: TrainEditEmailController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      title: '=',
      emailRestricted: '=',
      formOptions: '=',
    },
  });

  function TrainEditEmailController() {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.user.originalEmail = vm.user.email;
    }
  }
})();
