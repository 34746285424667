(function () {
  angular.module('kmi.lms.course.details').directive('courseDetailsBase', courseDetailsBase);

  function courseDetailsBase() {
    return {
      transclude: {
        formatData: '?courseDetailsBaseFormatData',
      },
      template: require('ajs/custom_modules/train/course/views/course-details-base.html').default,
      controller: 'CourseDetailsBaseController',
      controllerAs: 'vm',
      scope: {
        course: '=',
        userCourse: '=?',
        formatTabName: '@',
        modernView: '<?',
      },
      bindToController: true,
    };
  }
})();
