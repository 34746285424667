(function () {
  angular
    .module('kmi.lms.train.search', [
      'ui.mask',
      'ui.bootstrap',
      'kmi.lms.core',
      'kmi.lms.components',
      'kmi.lms.course.common',
      'kmi.lms.train.competency',
      'kmi.lms.search',
      'kmi.lms.course.registrations',
      'kmi.lms.customAttributes.course',
    ])
    .run(moduleRun);

  /* @ngInject */
  function moduleRun($state, searchFilters, $injector) {
    var searchState = $state.get('main.search');
    if (searchState) {
      searchState.data.availableFor = ['anonymous', 'regularUser', 'admin'];
    }

    searchFilters.onInit(onInit);

    searchFilters.sortTerms = [
      { name: 'Sort by relevance', value: 'relevance' },
      { name: 'Sort by format', value: 'format' },
      { name: 'Sort A-Z', value: 'asc' },
      { name: 'Sort Z-A', value: 'desc' },
      { name: 'Sort by newest', value: 'newest' },
      { name: 'Sort by avg. rating', value: 'avg_rating' },
      { name: 'Sort by # of ratings', value: 'rating_count' },
    ];

    function onInit() {
      var trainFilters = null,
        filterCpOrganization = null,
        filterCourseQuality = null,
        courseLabelFiler = null;

      $injector.invoke(
        function (trainSearchFilters, searchFilterCourseProviders, searchFilterCourseLabel, searchFilterCourseQuality) {
          trainFilters = trainSearchFilters;
          filterCpOrganization = searchFilterCourseProviders;
          filterCourseQuality = searchFilters.getFilter(searchFilterCourseQuality.getCourseQualityFilterConfig());

          courseLabelFiler = searchFilterCourseLabel;
        },
      );

      var // common
        commonFilter = new searchFilters.CommonFilter(),
        scheduleFilter = new searchFilters.ScheduleFilter(),
        updatedFilter = new searchFilters.UpdatedFilter(),
        // Visible filters
        objectFilter = new trainFilters.ObjectFilter(),
        courseFormatFilter = searchFilters.getFilter(courseLabelFiler),
        courseRatingFilter = new searchFilters.CourseRatingFilter(),
        tagFilter = new searchFilters.TagFilter(),
        subjectFilter = new searchFilters.SubjectFilter(),
        competencyNodeFilter = new trainFilters.CompetencyNodeFilter(),
        targetAudiencesFilter = new searchFilters.TargetAudienceFilter(),
        numberFilter = new searchFilters.NumberFilter(),
        creditTypeFilter = new searchFilters.CreditTypeFilter(),
        languageFilter = new searchFilters.CourseLanguageFilter(),
        singleGroupFilter = new trainFilters.SingleGroupFilter(),
        organizationFilter = searchFilters.getFilter(
          filterCpOrganization.getOrganizationFilterConfig({ mode: 'promptingFilter' }),
        );

      courseFormatFilter.label = 'Format';

      searchFilters.associateFilters(objectFilter, organizationFilter);
      searchFilters.associateFilters(objectFilter, courseFormatFilter);
      searchFilters.associateFilters(objectFilter, filterCourseQuality);

      var ui = [
          objectFilter,
          singleGroupFilter,
          courseFormatFilter,
          subjectFilter,
          filterCourseQuality,
          courseRatingFilter,
          commonFilter,
          competencyNodeFilter,
          creditTypeFilter,
          languageFilter,
        ],
        advanced = [organizationFilter, updatedFilter, scheduleFilter, targetAudiencesFilter, numberFilter],
        all = [
          objectFilter,
          courseFormatFilter,
          organizationFilter,
          singleGroupFilter,
          commonFilter,
          subjectFilter,
          scheduleFilter,
          updatedFilter,
          competencyNodeFilter,
          courseRatingFilter,
          filterCourseQuality,
          tagFilter,
          creditTypeFilter,
          languageFilter,
          targetAudiencesFilter,
          numberFilter,
        ];

      return { ui: ui, all: all, advanced: advanced };
    }
  }
})();
