(function () {
  angular
    .module('kmi.lms.train.user.learning', [
      'kmi.lms.core',
      'kmi.lms.components',
      'kmi.lms.course.registrations',
      'kmi.lms.user.learning',
      'kmi.lms.user.certificates',
      'kmi.lms.train.user.courses',
      'kmi.lms.train.user.learningSeries',
    ])
    .config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider
      // Main wrapper
      .state('main.learning', {
        url: '^/your-learning',
        data: {
          label: 'Your learning',
          availableFor: ['regularUser', 'admin'],
        },
        template: require('ajs/custom_modules/train/user/learning/template.html').default,
        redirectTo: 'main.learning.activeCourses',
      })
      // 1st Tab
      .state('main.learning.activeCourses', {
        url: '/?statuses&filter&dir',
        data: {
          label: 'Your learning',
          availableFor: ['regularUser', 'admin'],
        },
        params: {
          statuses: {
            value: null,
            dynamic: true,
          },
          filter: {
            value: null,
            dynamic: true,
          },
          dir: {
            value: null,
            dynamic: true,
          },
        },
        component: 'trainUserActiveCoursesTabComponent',
      })
      // 2nd Tab
      .state('main.learning.trainingPlans', {
        data: {
          label: 'Your learning',
          availableFor: ['regularUser', 'admin'],
        },
        url: '/training-plans',
        component: 'userLearningSeriesTree',
        resolve: {
          userId: function (currentUser) {
            return currentUser.get().id;
          },
        },
      })
      // 3rd Tab
      .state('main.learning.certificates', {
        url: '/certificates',
        data: {
          label: 'Your learning',
          availableFor: ['regularUser', 'admin'],
        },
        component: 'userCertificates',
      })
      // 4th Tab
      .state('main.learning.transcript', {
        url: '/transcript?filter',
        params: {
          filter: {
            value: null,
            dynamic: true,
          },
        },
        data: {
          label: 'Your learning',
          availableFor: ['regularUser', 'admin'],
        },
        component: 'trainUserTranscriptTabComponent',
      })
      .state('trainPendingCourseSurveys', {
        url: '/pending-surveys',
        component: 'trainPendingCourseSurveys',
        data: {
          label: 'Pending Surveys',
          availableFor: ['regularUser', 'admin'],
        },
      });
  }
})();
