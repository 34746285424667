(function () {
  angular.module('kmi.lms.course.details').config(moduleConfig);

  function moduleConfig($stateProvider, $provide, globalConfig) {
    const testingEnv = globalConfig.environment === 'testing';

    $provide.decorator('courseSessionDetailsDirective', function ($delegate) {
      var directive = $delegate[0];

      directive.template =
        require('ajs/custom_modules/train/course/views/formats/live-event/session-details.html').default;

      return $delegate;
    });

    $provide.decorator('collectionStructureLayerDirective', function ($delegate) {
      var directive = $delegate[0];

      directive.template =
        require('ajs/custom_modules/train/course/views/formats/collection/modern/collection-structure-layer.html').default;

      return $delegate;
    });

    $stateProvider
      .state('main.course.legacy', {
        url: '/details',
        component: 'courseDetailsBase',
        data: {
          courseFormatTypes: testingEnv ? [3] : [1, 3],
          label: 'Course',
          availableFor: ['anonymous', 'regularUser', 'admin'],
        },
        resolve: {
          course: function (courseObj) {
            return courseObj;
          },
        },
      })
      .state('main.course.liveEventLegacy', {
        url: '/live-event',
        component: 'liveEventDetails',
        data: {
          courseFormatTypes: [2, 7],
          label: 'Course',
          availableFor: ['anonymous', 'regularUser', 'admin'],
        },
        resolve: {
          course: function (courseObj) {
            return courseObj;
          },
        },
      });

    if (!testingEnv) {
      $stateProvider.state('main.course.compilationLegacy', {
        url: '/compilation',
        component: 'collectionDetails',
        data: {
          courseFormat: 104,
          label: 'Course',
          availableFor: ['anonymous', 'regularUser', 'admin'],
        },
      });
    }
  }
})();
