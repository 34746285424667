(function () {
  /**
   * @ngdoc component
   * @name kmi.lms.train.user.edit.component:trainEditAccountComponent
   *
   * @description
   * Provides "my account" functionality
   */

  angular.module('kmi.lms.train.user.edit').component('trainEditAccountComponent', {
    template: require('ajs/custom_modules/train/user/edit/account.html').default,
    controller: TrainEditAccountController,
    controllerAs: 'vm',
    bindings: {
      user: '<',
      plnGroups: '<?',
    },
  });

  /* @ngInject*/
  function TrainEditAccountController(
    $scope,
    $state,
    $q,
    $uibModal,
    $http,
    $timeout,
    backUrlService,
    currentUser,
    userRequirementsService,
    _,
  ) {
    var vm = this;
    vm.validationPending = false;

    vm.cancel = cancel;
    vm.saveAccount = saveAccount;
    vm.showForm = showForm;
    vm.$onInit = onInit;

    function onInit() {
      vm.currentStateName = $state.current.name;

      $scope.$on('event:user.saved', function (event, account) {
        currentUser.set(account);

        let formSetPristine = function () {
          if (vm.userAccountForm) {
            vm.userAccountForm.$setPristine();
          }
        };

        $timeout(formSetPristine, 0);
        $timeout(cancel, 0);
      });

      $scope.$on('event:validation.pending', function (event, pending) {
        vm.validationPending = pending;
      });
    }

    function cancel() {
      $q.all([
        userRequirementsService.check('portal_compliance'),
        userRequirementsService.check('email_confirmation'),
      ]).then(function (response) {
        if (!response[0]) {
          leaveNoncompliancePortal();
        } else if (!response[1]) {
          reloginRequired();
        } else if (!($state.transition?.isActive() || $state.current.name !== vm.currentStateName)) {
          backUrlService.goBack();
        }
      });
    }

    function leaveNoncompliancePortal() {
      var modalInstance = $uibModal.open({
        component: 'leaveNoncompliancePortalModal',
        keyboard: false,
      });

      return modalInstance.result;
    }

    function reloginRequired() {
      var modalInstance = $uibModal.open({
        component: 'reconfirmEmailRequiredDialog',
        keyboard: false,
      });

      return modalInstance.result;
    }

    function saveAccount() {
      $scope.$broadcast('event:user.save');
    }

    function showForm() {
      return _.get($state, 'current.name') === 'edit.account';
    }
  }
})();
