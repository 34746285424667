(function () {
  angular.module('kmi.lms.train.courseQuality').service('courseQualityService', courseQualityService);

  /* @ngInject */
  function courseQualityService($q, searchService) {
    var publicSearchService = searchService.get({ mode: 'public' });
    return {
      checkCdcQuality: checkCdcQuality,
    };

    function checkCdcQuality(courseId) {
      var coursesQuality = JSON.parse(sessionStorage.getItem('coursesQuality:' + courseId));

      if (coursesQuality !== null) {
        return $q.when(coursesQuality);
      } else {
        return publicSearchService
          .search({ type: 'course', id: courseId, qualityCheck: true })
          .then(function (response) {
            coursesQuality = response.data.items.length === 1 && response.data.items[0].quality === 'cdc';
            sessionStorage.setItem('coursesQuality:' + courseId, JSON.stringify(coursesQuality));

            return coursesQuality;
          });
      }
    }
  }
})();
