(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.edit.directive:trainEditAccount
   *
   * @description
   * Provides "my account" functionality
   */

  angular.module('kmi.lms.train.user.edit').directive('trainEditAccount', trainEditAccount);

  function trainEditAccount() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
        plnGroups: '=',
      },
      template: require('ajs/custom_modules/train/user/edit/components/edit-control.html').default,
      controller: TrainEditAccountController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject */
  function TrainEditAccountController(
    $scope,
    rootScopeService,
    $interval,
    $uiRouterGlobals,
    urlVersionService,
    formService,
    notificationService,
    _,
    customUserAttributesService,
    serverErrorHandlerService,
    $http,
  ) {
    var vm = this;
    vm.$onInit = onInit;

    vm.currentTab = $uiRouterGlobals.params.activeView || 'groupsInformationForm';
    vm.formOptions = { submitted: false };
    vm.profileCompletionPercentage = 0;
    vm.requiredFieldsStates = {};
    vm.completionFormsStates = {};
    vm.isFormIncomplete = {};

    vm.submitForm = submitForm;

    function onInit() {
      $scope.$on('event:modelCompleteness:changed', updateProfileCompletion);

      $scope.$watch('vm.user.groups', function (newValue, oldValue) {
        vm.plnEnabled = _.some(vm.user.groups, function (ug) {
          return _.some(vm.plnGroups, function (pg) {
            return pg.ileft <= ug.ileft && pg.iright >= ug.iright;
          });
        });

        if (newValue && newValue.length) {
          if (!angular.equals(_.sortBy(newValue), _.sortBy(oldValue))) {
            loadCustomUserAttributes();
          }
        } else {
          vm.customAttributesCategories = [];
        }
      });

      loadCustomUserAttributes(function () {
        $scope.$watch('vm.userForm.$valid', changingFormHandler);
      });
    }

    function loadCustomUserAttributes(callback) {
      customUserAttributesService
        .getAttributeCategories(
          {
            withAttributes: true,
            groups: angular.toJson(_.map(vm.user.groups, 'id')),
          },
          vm.user.id,
        )
        .then(function (res) {
          vm.customAttributesCategories = res;

          markCategoryRequired();

          if (angular.isFunction(callback)) {
            callback();
          }
        })
        .catch(function (reason) {
          serverErrorHandlerService.handleForbiddenError(reason);
        });

      function markCategoryRequired() {
        if (
          $uiRouterGlobals.params.activeView &&
          _.startsWith($uiRouterGlobals.params.activeView, 'customAttributeForm')
        ) {
          var categoryId = $uiRouterGlobals.params.activeView.substring('customAttributeForm'.length);

          if (categoryId && !isNaN(categoryId)) {
            categoryId = parseInt(categoryId, 10);

            _.forEach(vm.customAttributesCategories, function (cat) {
              if (categoryId === cat.id) {
                cat.required = true;
              }
            });
          }
        }
      }
    }

    function changingFormHandler(newValue, oldValue) {
      if (newValue !== oldValue) {
        recalculateCompletion();
      }
    }

    function recalculateCompletion() {
      formService.fulfillCompleteness(vm.userForm).then(function (result) {
        vm.isFormIncomplete = result.isFormIncomplete;
        vm.profileCompletionPercentage = result.profileCompletionPercentage;
        vm.requiredFieldsStates = result.requiredFieldsStates;
        vm.completionFormsStates = result.completenessFormsStates;
      });
    }

    function updateProfileCompletion(event, state) {
      _.assign(vm.requiredFieldsStates, state.input);
      _.merge(vm.completionFormsStates, state.form);
      fulfillProfileCompletion();
    }

    function fulfillProfileCompletion() {
      var completeness = formService.calculateCompleteness(vm.completionFormsStates, vm.requiredFieldsStates);
      vm.isFormIncomplete = completeness.isFormIncomplete;
      vm.profileCompletionPercentage = completeness.profileCompletionPercentage;
    }

    function submitForm() {
      vm.formOptions.submitted = true;
      $scope.$emit('event:validation.pending', true);

      let validationPending = $interval(function () {
        if (!vm.userForm.$pending) {
          if (!$http.pendingRequests.length) {
            $interval.cancel(validationPending);
            $scope.$broadcast('event:customAttributes.saving', vm.user);
            if (vm.userForm.$valid) {
              urlVersionService.update();

              notificationService.info('Saving ...');

              var userDraft = angular.copy(vm.user);

              userDraft.save().then(
                function () {
                  angular.extend(vm.user, userDraft);
                  rootScopeService.broadcast('event:user.saved', vm.user);
                  $scope.$emit('event:validation.pending', false);
                  vm.formOptions.submitted = false;
                  notificationService.info('Your changes have been saved.', 3e3);
                },
                function (response) {
                  var message = 'An error occurred saving user data. Please try again later.';
                  if (response.status === 403 && response.data && response.data.code) {
                    message = response.data.message;
                  }

                  notificationService.hideInfo();
                  notificationService.error(message, 5e3);
                  $scope.$emit('event:validation.pending', false);
                },
              );
            } else {
              notificationService.error('Please fill in fields correctly', 2e3);
              $scope.$emit('event:validation.pending', false);
            }
          }
        }
      }, 100);
    }
  }
})();
