(function () {
  /**
   * @ngdoc component
   * @name kmi.lms.train.user.edit.component:leaveNoncompliancePortalModal
   *
   * @description
   * Provide modal to confirm logout from non compliance portal
   */

  angular.module('kmi.lms.train.user.edit').component('leaveNoncompliancePortalModal', {
    template: require('ajs/custom_modules/train/user/edit/modal/leave-noncompliance-portal-template.html').default,
    controller: LeaveNoncompliancePortalController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '<',
    },
  });

  /* @ngInject */
  function LeaveNoncompliancePortalController() {
    var vm = this;
    vm.cancel = cancel;

    function cancel() {
      vm.modalInstance.dismiss();
    }
  }
})();
