(function () {
  angular.module('kmi.lms.train.user.registration').service('TrainRegistrationWizard', TrainRegistrationWizard);

  /* @ngInject */
  function TrainRegistrationWizard($q, $timeout) {
    function Wizard(initStep) {
      this.steps = [];

      this.addStep(angular.extend(initStep, { isFirstStep: true }));

      this.setStep(this.steps[0]);
    }

    Wizard.prototype._getStepIndex = function (step) {
      var index = 0,
        res = -1;

      angular.forEach(this.steps, function (currStep) {
        if (currStep.name === step.name) {
          res = index;
        }
        index++;
      });

      return res;
    };

    Wizard.prototype._unselectAll = function () {
      angular.forEach(this.steps, function (step) {
        step.isLastStep = false;
        step.selected = false;
      });

      this.currentStep = null;
    };

    Wizard.prototype.setStep = function (step) {
      var stepIndex = this._getStepIndex(step);

      this.currentStep = step;

      if (stepIndex === this.steps.length - 1) {
        this.currentStep.isLastStep = true;
      }

      if (this.currentStep.onEnter && angular.isFunction(this.currentStep.onEnter)) {
        this.currentStep.onEnter();
      }
    };

    Wizard.prototype.addStep = function (step) {
      var currentStepIndex = this.currentStep ? this._getStepIndex(this.currentStep) : 0;

      this._unselectAll();
      this.steps.push(step);

      this.setStep(this.steps[currentStepIndex]);
    };

    Wizard.prototype.removeStep = function (step) {
      var stepIndex = this._getStepIndex(step),
        currentStepIndex = this.currentStep ? this._getStepIndex(this.currentStep) : 0;

      if (stepIndex < 0 || stepIndex > this.steps.length - 1) {
        return;
      }

      if (stepIndex !== currentStepIndex) {
        this._unselectAll();
        this.steps.splice(stepIndex, 1);

        this.setStep(this.steps[currentStepIndex]);
      }
    };

    Wizard.prototype.nextStep = function () {
      var stepIndex = this._getStepIndex(this.currentStep),
        deferred = $q.defer(),
        self = this;

      this.stepChangePending = true;

      if (!this.canExitStep(this.currentStep)) {
        this.stepChangePending = false;
        $timeout(function () {
          deferred.reject();
        });

        return deferred.promise;
      }

      return this.executeResolvers(this.currentStep).then(function () {
        if (stepIndex === self.steps.length - 1) {
          return (
            self.onFinish &&
            self.onFinish().then(
              function () {},
              function () {
                self.stepChangePending = false;
              },
            )
          );
        }

        self.setStep(self.steps[stepIndex + 1]);

        self.stepChangePending = false;

        return self.currentStep;
      });
    };

    Wizard.prototype.prevStep = function () {
      var stepIndex = this._getStepIndex(this.currentStep);

      if (stepIndex === 0) {
        return;
      }

      this.setStep(this.steps[stepIndex - 1]);
    };

    Wizard.prototype.canExitStep = function (step) {
      var res = true,
        restrictions;

      if (step.restrictions) {
        restrictions = step.restrictions;

        if (angular.isArray(restrictions)) {
          angular.forEach(restrictions, function (restriction) {
            res = res && _evaluateRequirement(restriction);
          });
        } else {
          res = _evaluateRequirement(restrictions);
        }
      }

      return res;

      function _evaluateRequirement(restriction) {
        if (typeof restriction === 'function') {
          return restriction();
        }

        return restriction;
      }
    };

    Wizard.prototype.executeResolvers = function (step) {
      var deferred = $q.defer(),
        promises = [];

      if (step.resolve) {
        promises = this._getPromises(step.resolve);

        if (promises.length) {
          return $q.all(promises);
        }
      }

      $timeout(function () {
        deferred.resolve();
      });

      return deferred.promise;
    };

    Wizard.prototype._getPromises = function (resolvers) {
      var promises = [];

      if (typeof resolvers === 'function') {
        promises[0] = resolvers();
      } else if (angular.isArray(resolvers)) {
        resolvers.map(function (resolver) {
          promises.push(resolver());
        });
      } else if (angular.isObject(resolvers)) {
        Object.keys(resolvers).map(function (key) {
          promises.push(resolvers[key]());
        });
      }

      // leave only resolvers with promises
      promises = promises.filter(function (promise) {
        return promise !== undefined && angular.isFunction(promise.then);
      });

      return promises;
    };

    Wizard.prototype.getStepsCount = function () {
      return this.steps.length;
    };

    return Wizard;
  }
})();
