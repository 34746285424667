(function () {
  angular.module('kmi.lms.train.user.edit').directive('trainUserEditPln', trainUserEditPln);

  function trainUserEditPln() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
        formOptions: '=',
      },
      template: require('ajs/custom_modules/train/user/edit/components/pln.html').default,
      controller: TrainUserEditPlnController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject*/
  function TrainUserEditPlnController($scope, _) {
    var vm = this;

    vm.editLicence = editLicence;
    vm.saveLicence = saveLicence;
    vm.cancelEdit = cancelEdit;
    vm.deleteLicence = deleteLicence;
    vm.$onInit = onInit;

    function onInit() {
      vm.licenceToEdit = null;
      vm.user.licences = vm.user.licences || [];

      activate();
    }

    function activate() {
      $scope.$watchCollection('vm.user.licences', onUserLicencesChanged);
    }

    function onUserLicencesChanged() {
      // Collect licence ids
      vm.licenceIds = _.map(vm.user.licences, 'id');
    }

    /**
     * @description
     * Init licence to edit. It should equal to 'new' when licence parameter is falsy.
     * @param licence
     */
    function editLicence(licence) {
      vm.licenceToEdit = licence || 'new';
    }

    /**
     * @description
     * Applies provided data to the licence which is in edit mode
     * @param licenceData
     */
    function saveLicence(licenceData) {
      if (vm.licenceToEdit === 'new') {
        vm.user.licences.push(licenceData);
      } else {
        angular.extend(vm.licenceToEdit, licenceData);
      }

      cancelEdit();
    }

    /**
     * @description
     * Releases licence To Edit
     */
    function cancelEdit() {
      vm.licenceToEdit = null;
    }

    /**
     * @description
     * Removes provided licence from the user licences list
     * @param licence
     */
    function deleteLicence(licence) {
      vm.user.licences = _.without(vm.user.licences, licence);

      cancelEdit();
    }
  }
})();
