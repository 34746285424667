(function () {
  angular.module('kmi.lms.course.details').component('courseDetailsCertificates', {
    bindings: {
      course: '=',
      trackingName: '=',
    },
    template: require('ajs/custom_modules/train/course/components/course-details-certificates.html').default,
    controllerAs: 'vm',
  });
})();
