(function () {
  angular.module('kmi.lms.train.authorization').component('trainPortalComplianceComponent', {
    template: require('ajs/custom_modules/train/authorization/portal-compliance.html').default,
    controller: TrainPortalComplianceController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '<',
    },
  });

  /* @ngInject */
  function TrainPortalComplianceController($window, $state, navigationService, portalsService, globalConfig, _) {
    var vm = this;

    vm.navigateToPortal = navigateToPortal;

    activate();

    function activate() {
      vm.loading = portalsService
        .getUserPortals()
        .then(function (portals) {
          vm.portals = portals;

          if (vm.portals.length) {
            vm.targetPortal = vm.portals[0];
          }
        })
        .finally(function () {
          vm.loading = null;
        });
    }

    function navigateToPortal() {
      if (vm.targetPortal) {
        var targetState = navigationService.getTargetState();
        var targetPath =
          targetState && $state.current && targetState.name === $state.current.name
            ? null
            : navigationService.getRelativeTargetPath();
        var targetPortal = portalsService.getPortalPath(vm.targetPortal);

        $window.location.href = targetPath
          ? _.trimEnd(targetPortal, '/') + '/' + targetPath.replace(globalConfig.base, '')
          : targetPortal;
      }
    }
  }
})();
