(function () {
  angular.module('kmi.lms.train.user.registration').directive('pennsylvaniaAttributes', pennsylvaniaAttributes);

  /* @ngInject */
  function pennsylvaniaAttributes() {
    return {
      restrict: 'EA',
      controller: AttributesCtrl,
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        user: '=user',
        step: '=?step',
        affiliate: '=?affiliate',
      },
      template: require('ajs/custom_modules/train/user/registration/affiliates/components/pennsylvania-attributes.html')
        .default,
    };
  }

  /* @ngInject */
  function AttributesCtrl(_) {
    var vm = this;

    vm.steps = [null, 'customAttributes'];

    vm.nextStep = nextStep;
    vm.prevStep = prevStep;

    function nextStep() {
      var stepId = _.includes(vm.steps, vm.step) ? vm.steps.indexOf(vm.step) : 0;

      if (stepId === 0) {
        vm.steps[0] = vm.step;
      }

      stepId++;
      vm.step = vm.steps[stepId];
    }

    function prevStep() {
      var stepId = vm.steps.indexOf(vm.step);

      stepId--;
      vm.step = vm.steps[stepId];
    }
  }
})();
