(function () {
  angular.module('kmi.lms.train.help').component('trainHelpDirectory', {
    template: require('ajs/custom_modules/train/help/help-directory.html').default,
    controller: TrainHelpDirectoryController,
    controllerAs: 'vm',
  });

  function TrainHelpDirectoryController($state, $window, globalConfig, _) {
    const vm = this;

    vm.$onInit = onInit;
    vm.openMenuItem = openMenuItem;

    function openMenuItem(menuItem) {
      if (menuItem.state) {
        $state.go(menuItem.state, menuItem.stateParams);
      }

      if (menuItem.link) {
        var linkTemplate = _.template(menuItem.link);

        if (menuItem.target === '_blank') {
          $window.open(linkTemplate({ basePath: globalConfig.base }));
        } else {
          $window.location.href = linkTemplate({ basePath: globalConfig.base });
        }
      }
    }

    function onInit() {
      vm.helpItems = [
        {
          title: 'Forgot Password or Login Name?',
          description: 'Reset password or retrieve login name',
          icon: 'fa-solid fa-right-to-bracket',
          state: 'prompt.forgotPassword',
          link: null,
        },
        {
          title: 'Tutorials',
          description: 'Learn how to manage your TRAIN account and navigate your learning experience',
          icon: 'fa-solid fa-laptop-file',
          link: '/tutorials/',
          target: '_blank',
          state: null,
        },
        {
          title: 'FAQs',
          description: 'Review frequently asked questions about TRAIN',
          icon: 'fa-solid fa-circle-question',
          link: 'https://trainsupport.freshdesk.com/support/solutions/articles/47001252728-train-faqs',
          target: '_blank',
          state: null,
        },
        {
          title: 'Continuing Education',
          description: "Learn about TRAIN's continuing education (CE) options",
          icon: 'fa-solid fa-graduation-cap',
          link: 'https://trainsupport.freshdesk.com/support/solutions/articles/47001251241-continuing-education',
          target: '_blank',
          state: null,
        },
        {
          title: 'About TRAIN',
          description: 'Learn about the TRAIN learning network and its partner organizations',
          icon: 'fa-solid fa-circle-info',
          link: null,
          state: 'main.help.about',
        },
        {
          title: 'Contact Us',
          description: 'Request TRAIN support',
          icon: 'fa-solid fa-envelope ',
          link: null,
          state: 'main.contacts',
        },
        {
          title: 'Policies',
          description: "Review TRAIN's terms and conditions",
          icon: 'fa-solid fa-clipboard-list',
          link: null,
          state: 'main.help.policies',
        },
        {
          title: 'Accessibility Statement',
          description: "Review TRAIN's accessibility statement",
          icon: 'fa-solid fa-universal-access',
          link: null,
          state: 'main.help.accessibility',
        },
        {
          title: 'System Requirements',
          description: 'Review technical requirements for using TRAIN',
          icon: 'fa-solid fa-gear',
          link: null,
          state: 'main.help.requirements',
        },
      ];
    }
  }
})();
