(function () {
  angular.module('kmi.lms.train.user.learning').directive('trainCompetencyAssessment', trainCompetencyAssessment);

  function trainCompetencyAssessment() {
    return {
      restrict: 'EA',
      scope: true,
      template: require('ajs/custom_modules/train/user/learning/competency-assessment.html').default,
      controller: TrainCompetencyAssessmentCtrl,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject */
  function TrainCompetencyAssessmentCtrl($http, currentUser) {
    var vm = this,
      cacheKey;

    vm.competencyAssessments = [];

    cacheKey = 'competencyAssessment:' + currentUser.get().id;

    activate();

    function activate() {
      bindCachedData();

      $http.get('/a/user/competency_assessments/', { cache: true }).then(function (response) {
        vm.competencyAssessments = response.data;
        refreshCache();
      });
    }

    function refreshCache() {
      sessionStorage.setItem(cacheKey, JSON.stringify(vm.competencyAssessments));
    }

    function bindCachedData() {
      var cachedModel = JSON.parse(sessionStorage.getItem(cacheKey));
      if (cachedModel) {
        vm.competencyAssessments = cachedModel;
      }
    }
  }
})();
