(function () {
  angular.module('kmi.lms.course.details').component('courseAccreditationStatements', {
    template:
      require('ajs/custom_modules/train/course/components/accreditation-statements/course-accreditation-statements.html')
        .default,
    bindings: {
      course: '=',
      accreditationStatements: '=',
    },
    controller: CourseAccreditationStatementsController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function CourseAccreditationStatementsController(courseAccreditationsService) {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      if (!vm.accreditationStatements) {
        load();
      }
    }

    function load() {
      vm.loading = true;

      courseAccreditationsService
        .getCourseAccreditationStatements(vm.course.id)
        .then((accreditationStatements) => {
          vm.accreditationStatements = accreditationStatements;
        })
        .finally(() => {
          vm.loading = null;
        });
    }
  }
})();
