(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.edit.directive:trainEditOrganizationInfo
   *
   * @description
   * Provides functionality for edit user organization information
   */

  angular.module('kmi.lms.train.user.edit').directive('trainEditOrganizationInfo', trainEditOrganizationInfo);

  function trainEditOrganizationInfo() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
        formOptions: '=',
        view: '@?',
      },
      template: require('ajs/custom_modules/train/user/edit/components/organization-information.html').default,
      controller: TrainEditOrganizationInfoController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject*/
  function TrainEditOrganizationInfoController(userDictionaryService) {
    var vm = this;

    vm.getOrganizations = userDictionaryService.getOrganizations;
  }
})();
