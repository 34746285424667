(function () {
  angular.module('kmi.lms.train.competency').component('competenciesTreeView', {
    template: require('ajs/custom_modules/train/competency/competencies-tree-view-template.html').default,
    controller: TrainCompetenciesTreeViewController,
    bindings: {
      competencies: '=',
      selectedItems: '=',
    },
    controllerAs: 'vm',
  });

  /*@ngInject*/
  function TrainCompetenciesTreeViewController($scope, treeNodesService) {
    var vm = this;

    vm.$onInit = onInit;
    vm.onCheckedChanged = onCheckedChanged;

    function onInit() {
      treeNodesService.setCheckedNodes(vm.competencies, vm.selectedItems);
      treeNodesService.setParents(vm.competencies);

      $scope.$watch('vm.competencies', function (newValue, oldValue) {
        if (newValue && !angular.equals(newValue, oldValue)) {
          treeNodesService.setCheckedNodes(vm.competencies, vm.selectedItems);
          treeNodesService.setParents(vm.competencies);
        }
      });

      $scope.$watch('vm.selectedItems', function (newValue, oldValue) {
        if (newValue && !angular.equals(newValue, oldValue)) {
          treeNodesService.setCheckedNodes(vm.competencies, vm.selectedItems);
          if (vm.selectedItemsChanged) {
            vm.selectedItemsChanged({ selectedItems: vm.selectedItems });
          }
        }
      });
    }

    function onCheckedChanged(node) {
      treeNodesService.checkAll(node);
      vm.selectedItems = treeNodesService.getCheckedNodes(vm.competencies);
    }
  }
})();
