(function () {
  angular.module('kmi.lms.train.user.edit').factory('trainUserGroupsService', trainUserGroupsService);

  /* @ngInject */
  function trainUserGroupsService(Group, groupService, portalGroups, _) {
    return {
      getDefaultStates: getDefaultStates,
      getPortals: getPortals,
      getChildGroups: getChildGroups,
    };

    function getDefaultStates() {
      return groupService.getDefaultSelfRegGroups().then(function (groups) {
        return _.map(groups, function (dg) {
          return dg.group;
        });
      });
    }

    function getPortals() {
      return Group.query({ query: { group_ids: _.values(portalGroups), selfRegChild: true } }).$promise.then(
        function (portals) {
          return portals.items;
        },
      );
    }

    function getChildGroups(groupId) {
      return Group.query({
        query: {
          parent: groupId,
          selfReg: true,
          selfRegChild: true,
        },
      }).$promise;
    }
  }
})();
