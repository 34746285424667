(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.registration:MichiganAccountInformation
   *
   * @description
   * Michigan account creation attributes selection
   */
  angular.module('kmi.lms.train.user.registration').directive('miAccountInformation', miAccountInformation);

  /* @ngInject */
  function miAccountInformation() {
    return {
      restrict: 'EA',
      controller: AccountInformationCtrl,
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        user: '=user',
      },
      template: require('ajs/custom_modules/train/user/registration/affiliates/components/mi-account-information.html')
        .default,
    };
  }

  /* @ngInject */
  function AccountInformationCtrl(usPhoneMask) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.submitted = false;
      vm.user.groupByState = true;
      vm.user.groups = [];
      vm.phoneMask = usPhoneMask;
    }
  }
})();
