(function () {
  angular
    .module('kmi.lms.train.home', [
      'kmi.lms.core',
      'kmi.lms.course.common',
      'kmi.lms.user.notifications',
      'kmi.lms.user.mail',
      'kmi.lms.train.portals',
      'kmi.lms.integration',
    ])
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider) {
    $stateProvider
      .state('main.welcome', {
        //anonymous home page
        url: '^/welcome',
        component: 'trainWelcomeComponent',
        data: {
          label: 'Home',
          availableFor: ['anonymous'],
        },
      })
      .state('main.home', {
        url: '^/home?state',
        component: 'trainHomeComponent',
        params: {
          state: null,
        },
        data: {
          label: 'Home',
          availableFor: ['regularUser', 'admin'],
        },
      });
  }
})();
