(function () {
  angular.module('kmi.lms.train.authorization', ['kmi.lms.train.portals']).config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider.state('prompt.portalCompliance', {
      url: '/portal-compliance',
      component: 'trainPortalComplianceComponent',
      data: {
        label: 'Portal Compliance',
        availableFor: ['regularUser', 'admin'],
      },
    });
  }
})();
