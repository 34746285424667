(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.edit.directive:trainEditAccountInfo
   *
   * @description
   * Provides functionality for edit user account information
   */

  angular.module('kmi.lms.train.user.edit').directive('trainEditAccountInfo', trainEditAccountInfo);

  function trainEditAccountInfo() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
        formOptions: '=',
        userForm: '=',
      },
      template: require('ajs/custom_modules/train/user/edit/components/account-information.html').default,
      controller: TrainEditAccountInfoController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject*/
  function TrainEditAccountInfoController(
    $scope,
    approvalStatus,
    _,
    backUrlService,
    $uibModal,
    userPasswordService,
    siteSettingService,
  ) {
    var vm = this;

    vm.approvalStatuses = approvalStatus;
    vm.siteSettings = siteSettingService.getSiteSetting();
    vm.email = null;

    vm.resetPassword = resetPassword;
    vm.requestCourseProviderRole = requestCourseProviderRole;
    vm.$onInit = activate;

    function activate() {}

    function resetPassword() {
      userPasswordService.changePasswordDialog(vm.user);
    }

    function requestCourseProviderRole() {
      function saveUser() {
        $scope.$on('event:user.saved', goToProviderSurvey);
        $scope.$emit('event:user.save');
      }

      // save user before redirect to edit provider
      if (vm.userForm.$dirty) {
        var modalInstance = $uibModal.open({
          component: 'saveProfileModalComponent',
          backdrop: 'static',
          keyboard: false,
        });

        modalInstance.result.then(saveUser);
      } else {
        goToProviderSurvey();
      }
    }

    function goToProviderSurvey() {
      if (vm.user.courseProvider) {
        backUrlService.passThroughRedirect('edit.provider_survey', { courseProvider: vm.user.courseProvider });
      } else {
        backUrlService.passThroughRedirect('edit.provider_survey');
      }
    }
  }
})();
