(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.edit.directive:trainEditAddressInfo
   *
   * @description
   * Provides functionality for edit user address information
   */

  angular.module('kmi.lms.train.user.edit').directive('trainEditAddressInfo', trainEditAddressInfo);

  function trainEditAddressInfo() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
        formOptions: '=',
        view: '@?',
      },
      template: require('ajs/custom_modules/train/user/edit/components/address-information.html').default,
      controller: TrainEditAddressInfoController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject*/
  function TrainEditAddressInfoController(geoService, countryWithStates, regexPatterns, userDictionaryService, _) {
    var vm = this;

    vm.states = [];
    vm.zipPattern = '';

    vm.countryChange = countryChange;
    vm.getCities = userDictionaryService.getCities;

    activate();

    function activate() {
      geoService.getCountries(true).toPromise().then(bindCountries).then(bindStates);
      geoService.getTimeZones().toPromise().then(bindTimeZones);
    }

    function bindCountries(countries) {
      vm.countries = countries;
    }

    function bindTimeZones(timeZones) {
      vm.timeZones = timeZones;
    }

    function countryChange(change) {
      vm.user.stateId = null;
      vm.user.state = null;
      vm.user.stateName = null;
      bindStates(change);
    }

    function bindStates(change) {
      vm.isCurrentCountryWithStates = false;
      vm.zipPattern = regexPatterns.internationalZip;

      if (vm.user.country) {
        vm.isCurrentCountryWithStates = _.includes(countryWithStates, vm.user.country);

        if (vm.user.country === 'US') {
          vm.zipPattern = regexPatterns.usZip;
        }

        if (vm.isCurrentCountryWithStates) {
          geoService
            .getStates(vm.user.country)
            .toPromise()
            .then(function (states) {
              vm.states = states;
              vm.user.stateValueOther = '';
            });
        } else {
          vm.states = [];
          if (vm.view === 'admin' && change) {
            vm.user.stateValueOther = 'Other';
          }
        }
      }
    }
  }
})();
