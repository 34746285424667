(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.registration:kansasAttributes
   *
   * @description
   * Vansas account creation attributes selection
   */
  angular.module('kmi.lms.train.user.registration').directive('kansasAttributes', kansasAttributes);

  /* @ngInject */
  function kansasAttributes() {
    return {
      restrict: 'EA',
      controller: AttributesCtrl,
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        user: '=user',
        step: '=?step',
        affiliate: '=?affiliate',
      },
      template: require('ajs/custom_modules/train/user/registration/affiliates/components/kansas-attributes.html')
        .default,
    };
  }

  /* @ngInject */
  function AttributesCtrl($scope, _, userPlnService) {
    var vm = this;

    vm.kansasAttributes = {
      studentNumber: { id: 2511 },
    };
    vm.nextStep = nextStep;
    vm.prevStep = prevStep;
    vm.steps = [null, 'customAttributes'];

    activate();

    function activate() {
      var plnGroups = [];

      userPlnService.getGroups().then(function (groups) {
        plnGroups = groups;
      });

      $scope.$watch(
        'vm.user.groups',
        function () {
          var plnEnabled = _.some(vm.user.groups, function (ug) {
            return _.some(plnGroups, function (pg) {
              return pg.ileft <= ug.ileft && pg.iright >= ug.iright;
            });
          });

          if (plnEnabled && vm.steps.indexOf('plnAttributes') === -1) {
            vm.steps.push('plnAttributes');
          }
        },
        true,
      );

      $scope.$on('event:user:beforeSave', prepareAttributesForSave);
    }

    function nextStep() {
      var stepId = _.includes(vm.steps, vm.step) ? vm.steps.indexOf(vm.step) : 0;

      if (stepId === 0) {
        vm.steps[0] = vm.step;
      }

      stepId++;
      vm.step = vm.steps[stepId];
    }

    function prevStep() {
      var stepId = vm.steps.indexOf(vm.step);

      stepId--;
      vm.step = vm.steps[stepId];
    }

    function prepareAttributesForSave(event, model) {
      model.attributes = vm.user.attributes || {};
      angular.extend(model.attributes, vm.kansasAttributes);
    }
  }
})();
