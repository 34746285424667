(function () {
  angular
    .module('kmi.lms.train.user.edit', [
      'kmi.lms.core',
      'kmi.lms.user.common',
      'kmi.lms.user.components',
      'kmi.lms.train.user',
      'kmi.lms.user.mail',
      'kmi.lms.components',
      'kmi.lms.customAttributes.common',
      'kmi.lms.courseProviders',
    ])
    .constant('countryWithStates', ['US', 'CA'])
    .constant('phoneTypes', {
      work: 'Work',
      home: 'Home',
      mobile: 'Mobile',
    })
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider) {
    var groupEditParams = {
      accountSettings: function (settingsService) {
        return settingsService.getSetting(['MyAccount.ExcludedGroups']);
      },
      rootGroups: function (trainUserGroupsService, portalGroups, _) {
        var rootGroups = [];

        if (portalGroups.state) {
          rootGroups.push({
            name: 'Location',
            id: portalGroups.state,
            description: 'Select the location where you work, study, or reside.',
          });
        }

        if (portalGroups.mrc) {
          rootGroups.push({
            name: 'MRC',
            id: portalGroups.mrc,
            description:
              'If you are a member of the Medical Reserve Corps, then you should select MRC Portal in addition to your state.',
          });
        }

        if (portalGroups.cdc) {
          rootGroups.push({
            name: 'CDC',
            id: portalGroups.cdc,
            description:
              'To access additional CDC TRAIN (Centers for Disease Control and Prevention) content, and to participate in CDC-hosted communities of practice, you should also add the CDC Portal.',
          });
        }

        if (portalGroups.vha) {
          rootGroups.push({
            name: 'VHA',
            id: portalGroups.vha,
            description:
              'To access additional Veterans Health Administration, Employee Education System content, you should add the VHA Portal.',
          });
        }

        if (portalGroups.vba) {
          rootGroups.push({
            name: 'VBA MDE',
            id: portalGroups.vba,
            description:
              'To access Veterans Benefits Administration, Military Medical Disability Examinations content, you must add the VBA portal to your group selection.',
          });
        }

        if (portalGroups.medLearning) {
          rootGroups.push({
            name: 'Med Learning',
            id: portalGroups.medLearning,
            description: 'To access additional Med Learning content, your should add the Med Learning portal.',
          });
        }

        if (portalGroups.curated) {
          rootGroups.push({
            name: 'Curated Content',
            id: portalGroups.curated,
            description:
              'To Access additional content from The Campaign for Inclusive Care, you should add the Inclusive Care portal',
          });
        }

        if (portalGroups.fda) {
          rootGroups.push({
            name: 'FDA',
            id: portalGroups.fda,
            description: 'If you are a member of the FDA, then you should select FDA Portal in addition to your state.',
          });
        }

        return trainUserGroupsService.getPortals().then(function (groups) {
          var groupsObj = _.groupBy(groups, 'id');

          rootGroups = _.map(rootGroups, function (g) {
            return _.extend(_.get(groupsObj, '[' + g.id + '][0]', {}), g);
          });

          return rootGroups;
        });
      },
      defaultState: function (trainUserGroupsService, _) {
        return trainUserGroupsService.getDefaultStates().then(function (states) {
          return _.head(states);
        });
      },
    };

    $stateProvider
      .state('edit.account', {
        url: '/account?activeView',
        component: 'trainEditAccountComponent',
        params: {
          activeView: null,
        },
        data: {
          label: 'Account',
          availableFor: ['regularUser', 'admin'],
        },
        resolve: {
          user: function ($http, currentUser, User, attributesService, _) {
            return User.get().then(function (user) {
              attributesService.clearCache();

              if (user.attributes) {
                _.each(user.attributes, function (attribute) {
                  attributesService.saveToCache(attribute);
                });
              }

              currentUser.set(user); // NB. The currentUser dont contains courseProvider

              return $http.get('/a/user/' + user.id + '/courses/provider/').then(function (response) {
                user.courseProvider = response.data.provider;

                return user;
              });
            });
          },
          plnGroups: function (userPlnService) {
            return userPlnService.getGroups();
          },
        },
      })
      .state('edit.account.groups', {
        url: '/groups',
        component: 'trainUserGroupSelectionComponent',
        data: {
          label: 'Account groups',
          availableFor: ['regularUser', 'admin'],
        },
        resolve: groupEditParams,
      })
      .state('edit.account.group', {
        url: '/group/{groupId:int}',
        component: 'trainEditUserGroupComponent',
        params: {
          groupId: null,
        },
        data: {
          label: 'Account groups',
          availableFor: ['regularUser', 'admin'],
        },
        resolve: angular.extend(
          {
            group: function ($transition$, $q, Group) {
              return Group.get({ groupId: $transition$.params().groupId }).$promise.then(function (group) {
                angular.extend(group, {
                  locked: false,
                });
                return group;
              });
            },
          },
          groupEditParams,
        ),
      })
      .state('edit.ems-checkup', {
        url: '/ems-checkup',
        component: 'trainCheckEmsAccountComponent',
        data: {
          label: 'EMS Checkup',
          availableFor: ['regularUser', 'admin'],
        },
      });
  }
})();
