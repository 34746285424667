(function () {
  angular.module('kmi.lms.course.details').component('collectionStructure', {
    template: require('ajs/custom_modules/train/course/views/formats/collection/course-list-tree.html').default,
    controller: CollectionStructureController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      item: '=',
      registration: '=',
    },
  });

  /* @ngInject */
  function CollectionStructureController(
    Course,
    LearningObjectRegistration,
    LearningObjectRegistrationWorkflow,
    serverErrorHandlerService,
    elms,
    trainingService,
    courseRegistrationStatus,
    globalConfig,
  ) {
    var vm = this;

    vm.formatPrice = elms.formatPrice;
    vm.isNumber = angular.isNumber;
    vm.toggleCourseDetails = toggleCourseDetails;
    vm.trainingService = trainingService;
    vm.getStatusTitle = courseRegistrationStatus.getTitle;
    vm.getStatusName = courseRegistrationStatus.getStatusName;
    vm.settings = globalConfig.settings;
    vm.globalCourseRegistrationSettings = globalConfig.courseRegistration || {};

    vm.$onInit = onInit;

    function onInit() {
      vm.item.contentLoading = null;
    }

    function toggleCourseDetails(item) {
      item.contentOpen = !item.contentOpen;
      if (item.contentOpen) {
        loadingSubCourseDetails(item);
      }
    }

    function loadingSubCourseDetails(item) {
      if (!item.contentLoading && !item.content) {
        item.contentLoading = true;

        Course.get(
          { courseId: item.id },
          function (course) {
            item.content = course;
          },
          function (reason) {
            item.content = null;

            // Pass empty callback, because we don't need show notification with error message
            return serverErrorHandlerService.handleForbiddenError(reason, function () {});
          },
        ).$promise.finally(function () {
          item.contentLoading = false;
        });
      }
    }
  }
})();
