(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.registration:illinoisAccountInformation
   *
   * @description
   * Illinois account creation attributes selection
   */
  angular
    .module('kmi.lms.train.user.registration')
    .directive('accountInformationDirective', accountInformationDirective);

  /* @ngInject */
  function accountInformationDirective() {
    return {
      restrict: 'EA',
      controller: AccountInformationCtrl,
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        user: '=user',
        submitHandler: '&onSubmit',
        hasSubmit: '=hasSubmit',
        byZipSelection: '=?byZipSelection',
        submitBtnLabel: '<?',
        fullAccountFieldset: '<?',
        groupByState: '<?',
      },
      template: require('ajs/custom_modules/train/user/registration/affiliates/components/account-information.html')
        .default,
    };
  }

  /* @ngInject */
  function AccountInformationCtrl(
    $scope,
    geoService,
    regexPatterns,
    userDictionaryService,
    countryWithStates,
    phoneTypes,
    usPhoneMask,
    $http,
    _,
  ) {
    var vm = this;

    vm.phoneMask = usPhoneMask;
    vm.getCities = userDictionaryService.getCities;
    vm.countryChange = countryChange;
    vm.stateChange = stateChange;
    vm.submit = submit;

    vm.$onInit = onInit;

    function onInit() {
      vm.submitted = false;
      vm.loading = true;
      vm.pendingRequestsLoading = false;
      vm.hasSubmit = true;
      vm.user.groupByState = angular.isDefined(vm.groupByState) ? vm.groupByState : true;
      vm.user.groups = [];
      vm.phone = vm.user.illinoisPhone || {};
      vm.phoneTypes = [];
      vm.workPhoneType = phoneTypes.work;

      activate();
    }

    function activate() {
      $scope.$watch(
        function () {
          return $http.pendingRequests.length;
        },
        function () {
          vm.pendingRequestsLoading = !!$http.pendingRequests.length;
        },
      );

      vm.phoneTypes = _.toArray(phoneTypes);
      geoService.getCountries(true).toPromise().then(bindCountries).then(initAddressByZipCode);

      $scope.$watch(
        'vm.phone',
        function (p) {
          updatePhones(angular.copy(p));
        },
        true,
      );

      if (vm.byZipSelection) {
        vm.hasSubmit = false;
      }

      $scope.$on('event:user:beforeSave', prepareUserForSave);

      $scope.$on('$destroy', function () {
        vm.hasSubmit = false;
      });
    }

    function initAddressByZipCode() {
      if (!vm.user.zip) {
        return;
      }

      geoService
        .getStateByZip(vm.user.zip, true)
        .toPromise()
        .then(function (state) {
          if (state && state.id) {
            vm.user.country = state.country.alpha2Code;
            vm.user.stateId = state.id;
            vm.user.city = state.city ? _.capitalize(state.city.toLowerCase()) : '';
            bindStates();
          } else if (vm.user.stateId) {
            vm.user.stateId = null;
          }

          vm.loading = false;
        });
    }

    function bindCountries(countries) {
      vm.countries = countries;
    }

    function bindCities(cities) {
      vm.cities = cities;
    }

    function countryChange() {
      vm.user.stateId = null;
      vm.user.state = null;
      vm.user.stateName = null;
      bindStates();
    }

    function bindStates() {
      vm.isCurrentCountryWithStates = false;
      vm.zipPattern = regexPatterns.internationalZip;

      if (vm.user.country) {
        vm.isCurrentCountryWithStates = _.includes(countryWithStates, vm.user.country);

        if (vm.user.country === 'US') {
          vm.zipPattern = regexPatterns.usZip;
          vm.phoneMask = usPhoneMask;
        } else {
          vm.phoneMask = '';
          vm.zipPattern = regexPatterns.internationalZip;
        }

        if (vm.isCurrentCountryWithStates) {
          vm.loading = true;
          geoService
            .getStates(vm.user.country)
            .toPromise()
            .then(function (states) {
              vm.states = states;
              vm.user.stateValueOther = '';
              vm.loading = false;
            });
        } else {
          vm.states = [];
        }

        stateChange();
      }
    }

    function stateChange() {
      if (vm.user.country === 'US' && vm.user.stateId) {
        vm.loading = true;
        geoService
          .getCities(vm.user.stateId)
          .toPromise()
          .then(function (cities) {
            bindCities(cities);
            vm.user.city = _.includes(cities, vm.user.city) ? vm.user.city : '';
            vm.loading = false;
          });
      } else {
        bindCities([]);
      }
    }

    function updatePhones(updatedPhone) {
      var work, home, mobile;

      if (vm.user) {
        vm.user.illinoisPhone = updatedPhone;
      }

      if (!vm.user || !updatedPhone.number || !updatedPhone.type) {
        return;
      }

      work = updatedPhone.type === phoneTypes.work ? updatedPhone : 0;
      home = updatedPhone.type === phoneTypes.home ? updatedPhone : 0;
      mobile = updatedPhone.type === phoneTypes.mobile ? updatedPhone : 0;

      if (work) {
        vm.user.phone = work.number;
        vm.user.extension = work.extension;
      } else if (vm.user.phone || vm.user.extension) {
        vm.user.phone = vm.user.extension = null;
      }
      if (home) {
        vm.user.eveningPhone = home.number;
      } else if (vm.user.eveningPhone) {
        vm.user.eveningPhone = null;
      }
      if (mobile) {
        vm.user.mobile = mobile.number;
      } else if (vm.user.mobile) {
        vm.user.mobile = null;
      }
    }

    function submit() {
      vm.submitted = true;
      if (vm.accountInformationForm.$valid) {
        vm.submitHandler();
      }
    }

    function prepareUserForSave(event, model) {
      // illinoisPhone an extra field that requiered on this page. So we need to remove it from the post data.
      delete model.illinoisPhone;
    }
  }
})();
