(function () {
  angular.module('kmi.lms.core').config(coreModuleConfig);

  function coreModuleConfig($stateProvider) {
    $stateProvider
      .state('main', {
        url: '/u',
        template: require('ajs/custom_modules/train/app/base-view.html').default,
        abstract: true,
      })
      .state('mainPrint', {
        url: '/p',
        template: require('ajs/custom_modules/train/app/base-view-print.html').default,
        abstract: true,
      })
      .state('prompt', {
        url: '/prompt',
        component: 'trainPromptView',
        abstract: true,
      });
  }

  angular.module('elmsSpaApp').run(moduleRun).config(moduleConfig);

  function moduleRun($state) {
    const groupManageUsersState = $state.get('edit.groupManageUsers');
    if (groupManageUsersState) {
      groupManageUsersState.data.label = 'Group membership';
    }
  }

  function moduleConfig($provide) {
    $provide.decorator('accessDeniedDirective', function ($delegate) {
      var directive = $delegate[0];

      directive.template = require('ajs/custom_modules/train/static/access-denied.html').default;

      return $delegate;
    });
  }
})();
