(function () {
  angular.module('kmi.lms.train.user').component('trainUserGroupSelectionComponent', {
    template: require('ajs/custom_modules/train/user/groups/add-groups.html').default,
    controller: TrainUserGroupSelectionController,
    controllerAs: 'vm',
    bindings: {
      user: '<',
      group: '<',
      rootGroups: '<', // Define root groups like "State", "MRC", "CDC", "VHA"
      defaultState: '<',
      accountSettings: '<',
    },
  });

  /* @ngInject */
  function TrainUserGroupSelectionController($scope, $timeout, backUrlService, portalGroups, _) {
    var vm = this,
      assignedState;

    vm.lastSelectedGroup = null; // Will hold last group selected by user in the current group path
    vm.userGroups = []; // List of groups which on user will be assigned

    vm.saveGroups = saveGroups;
    vm.goToAccountEdit = goToAccountEdit;

    vm.$onInit = onInit;

    function onInit() {
      assignedState = _.filter(vm.user.groups, function (ug) {
        return ug.categoryId === 12 && getGroupPortal(ug).id === portalGroups.state;
      });

      $scope.$watch('vm.userGroups', resetGroupSelector, true);
    }

    /**
     * @description
     * Set selector params to defaults and link new group selector
     */
    function resetGroupSelector() {
      if (vm.userGroups && !vm.userGroups.length) {
        vm.startSelector = false; //init or dismiss group selector
        $timeout(function () {
          vm.lastSelectedGroup = null;
          vm.selectedGroupPath = [];
          vm.startSelector = true;
        });
      }
    }
    /**
     * @description
     * Assigns groups to user
     */
    function saveGroups() {
      var isStateSelected = _.some(vm.userGroups, function (g) {
        return getGroupPortal(g).id === portalGroups.state;
      });

      // Filter user groups which are not selfRegistrationAllowed or for which selected deeper level
      vm.user.groups = _.filter(vm.user.groups, function (ug) {
        return (
          ug.selfRegistrationAllowed ||
          !_.find(vm.userGroups, function (group) {
            return ug.ileft < group.ileft && ug.iright > group.iright;
          })
        );
      });

      // drop previously selected state if state is selected here
      if (isStateSelected && assignedState) {
        vm.user.groups = _.without(vm.user.groups, assignedState);
      }

      vm.user.groups = vm.user.groups.concat(vm.userGroups);

      goToAccountEdit();
    }

    function getGroupPortal(group) {
      var portal = _.find(vm.rootGroups, function (rootGroup) {
        return (
          rootGroup.id !== portalGroups.state && group.ileft >= rootGroup.ileft && group.iright <= rootGroup.iright
        );
      });

      if (!portal) {
        portal = _.find(vm.rootGroups, function (rootGroup) {
          return (
            rootGroup.id === portalGroups.state && group.ileft >= rootGroup.ileft && group.iright <= rootGroup.iright
          );
        });
      }

      return portal || group;
    }

    /**
     * @description
     * Redirects to the edit account state. Skip saving to the backUrls stack.
     */
    function goToAccountEdit() {
      backUrlService.passThroughRedirect('edit.account');
    }
  }
})();
