(function () {
  angular.module('kmi.lms.train.user.edit').component('selfRegGroupList', {
    template: require('ajs/custom_modules/train/user/groups/self-reg-group-list.html').default,
    controller: SelfRegGroupListController,
    controllerAs: 'vm',
    bindings: {
      groups: '=',
      editable: '=',
      linkSubGroups: '=?',
    },
  });

  /* @ngInject */
  function SelfRegGroupListController($scope, $uibModal, backUrlService, portalGroups, Group, authorizationService, _) {
    var vm = this;

    vm.removeGroup = removeGroup;
    vm.editGroup = editGroup;
    vm.buildAffiliates = buildViewGroups;
    vm.relativeGroupPath = relativeGroupPath;
    vm.$onInit = activate;

    activate();

    function activate() {
      vm.loaded = false;
      if (vm.linkSubGroups) {
        loadStateDescendants().then(function () {
          buildViewGroups();
          vm.loaded = true;
        });
      } else {
        vm.viewGroups = vm.groups;
        vm.loaded = true;
      }

      $scope.$watch(
        'vm.groups',
        function (newValue, oldValue) {
          if (!angular.equals(_.sortBy(newValue), _.sortBy(oldValue))) {
            buildViewGroups();
          }
        },
        true,
      );
    }

    /**
     * @description
     * Create viewGroup model for linkSubGroups mode
     * 1) extract parent groups for saved user affiliates groups
     * 2) assign to model
     * 2) assign sub groups to these parents in model
     * 3) assign remain groups to model
     */
    function buildViewGroups() {
      var affiliateGroupsComponents = _.filter(vm.stateDescendants, function (g) {
        return _.some(vm.groups, function (ug) {
          return ug.ileft > g.ileft && ug.iright < g.iright && g.id !== portalGroups.state && g.id !== ug.id;
        });
      });

      // Assign parent groups with subgroups
      vm.viewGroups = _.map(affiliateGroupsComponents, assignSubGroups);

      var assignedSubGroups = _.flatten(_.map(vm.viewGroups, 'affiliateItems'));

      // Add remained groups
      vm.viewGroups = _.union(
        vm.viewGroups,
        _.filter(vm.groups, function (ug) {
          return !_.includes(assignedSubGroups, ug);
        }),
      );

      /**
       * @description
       * Extend group with its subgroups
       * @param group
       */
      function assignSubGroups(group) {
        var subGroups = _.filter(vm.groups, function (g) {
          return g.ileft > group.ileft && g.iright < group.iright && g.id !== group.id;
        });
        return angular.extend(group, { affiliateItems: subGroups });
      }
    }

    /**
     * @description
     * Loads state group descendants and assign them to controllers model
     */
    function loadStateDescendants() {
      return Group.query(
        {
          query: {
            parent: _.head(_.values(portalGroups)),
            selfReg: true,
            selfRegChild: true,
          },
        },
        function (response) {
          vm.stateDescendants = response.items;
        },
      ).$promise;
    }

    function removeGroup(group) {
      if (vm.editable) {
        confirmDeletion(group).then(function () {
          removeGroupAndUpdateModel(group);
        });
      } else {
        removeGroupAndUpdateModel(group);
      }
      $scope.$emit('event:selectedGroupsRemoved');
    }

    /**
     * @description
     * Removes group from user group model and rebuilds view gropu model structure
     * @param group
     */
    function removeGroupAndUpdateModel(group) {
      var groupsToRemove = hasSubGroups(group) ? group.affiliateItems : [group];

      vm.groups = _.filter(vm.groups, function (g) {
        return !_.includes(_.map(groupsToRemove, 'id'), g.id);
      });

      buildViewGroups();
    }

    function editGroup(group) {
      if (!authorizationService.getAuthorizationCookie()) {
        authorizationService.authorizeState('edit.account.group', { groupId: group.id });
      }
      backUrlService.passThroughRedirect('edit.account.group', { groupId: group.id });
    }

    function hasSubGroups(group) {
      return vm.linkSubGroups && group.affiliateItems && group.affiliateItems.length;
    }

    function confirmDeletion(group) {
      var modalInstance = $uibModal.open({
        component: 'trainUserGroupsRemoveAffiliatesConfirmationModal',
        keyboard: false,
        resolve: {
          affiliatePath: function () {
            return group.path;
          },
        },
      });

      return modalInstance.result;
    }

    function relativeGroupPath(groupPath, subGroupPath) {
      if (groupPath && groupPath.length && subGroupPath.indexOf(groupPath) === 0) {
        return subGroupPath.slice(groupPath.length ? groupPath.length + 1 : 0);
      } else {
        return subGroupPath;
      }
    }
  }
})();
