(function () {
  /**
   * @ngdoc directive
   * @name kmi.lms.train.user.registration:wyomingAccountInformation
   *
   * @description
   * Wyoming account creation attributes selection
   */
  angular.module('kmi.lms.train.user.registration').directive('wyomingWdhAttributes', wyomingWdhAttributes);

  /* @ngInject */
  function wyomingWdhAttributes() {
    return {
      restrict: 'EA',
      controller: AttributesCtrl,
      controllerAs: 'vm',
      bindToController: true,
      scope: {
        user: '=user',
        step: '=?step',
        affiliate: '=?affiliate',
      },
      template: require('ajs/custom_modules/train/user/registration/affiliates/components/wyoming-wdh-attributes.html')
        .default,
    };
  }

  /* @ngInject */
  function AttributesCtrl($scope, _, Group) {
    var vm = this;

    var wdhGroup;

    vm.submitted = false;
    vm.wdhStep = false;

    vm.hasWDH = hasWDH;
    vm.addAttribute = addAttribute;

    vm.nextStep = nextStep;
    vm.prevStep = prevStep;
    vm.steps = [null, 'customAttributes'];

    vm.$onInit = activate;

    function activate() {
      Group.get({ groupId: 53054 }).$promise.then(function (group) {
        wdhGroup = group;
      });

      vm.wyomingAttributes = {
        wdhEmployeeNumber: { id: 2661 },
      };

      if (!vm.user.attributes) {
        vm.user.attributes = [];
      }
    }

    function addAttribute() {
      //Duplicate prevention
      var intersection = false;
      var result = vm.wyomingAttributes.wdhEmployeeNumber;
      _.forEach(vm.user.attributes, function (attribute, key) {
        if (attribute.id === 2661) {
          vm.user.attributes[key] = result;
          intersection = true;
        }
      });
      if (!intersection) {
        vm.user.attributes.push(result);
      }
    }

    $scope.$on('$destroy', function () {
      vm.user.attributes = [];
    });

    function hasWDH() {
      var result = false;

      if (vm.user.groups && vm.user.groups.length > 0 && wdhGroup) {
        _.forEach(vm.user.groups, function (group) {
          if (group.ileft >= wdhGroup.ileft && group.iright <= wdhGroup.iright) {
            result = true;
          }
        });
      }

      return result;
    }

    function nextStep() {
      var stepId = _.includes(vm.steps, vm.step) ? vm.steps.indexOf(vm.step) : 0;

      // save as first step inner group selection step
      if (stepId === 0) {
        vm.steps[0] = vm.step;
      }

      stepId++;
      vm.step = vm.steps[stepId];
      if (vm.step === 'customAttributes') {
        vm.wyomingAttributes.wdhEmployeeNumber.value = vm.wdhEmployeeNumber;
        addAttribute();
      }
    }

    function prevStep() {
      var stepId = vm.steps.indexOf(vm.step);

      stepId--;
      vm.step = vm.steps[stepId];

      vm.user.attributes = [];
      vm.wdhEmployeeNumber = vm.wyomingAttributes.wdhEmployeeNumber.value;
      delete vm.wyomingAttributes.wdhEmployeeNumber.value;
    }
  }
})();
