(function () {
  angular.module('kmi.lms.train.user', ['ui.router', 'kmi.lms.user']).config(moduleConfig).value('portalGroups', {
    // When add group to this value, also pay attention on setting 'MyAccount.ExcludedGroups'
    state: 1,
    mrc: 9267,
    cdc: 34408,
    vba: 56378,
    vha: 51851,
    medLearning: 53238,
    fda: 54250,
  });

  /* @ngInject */
  function moduleConfig($provide) {
    $provide.decorator('portalGroups', portalGroupsDecorator);

    /*@ngInject*/
    function portalGroupsDecorator($delegate, siteSettingService) {
      var siteSettings = siteSettingService.getSiteSetting();

      if (siteSettings.basePath === 'inclusivecare') {
        $delegate = {
          curated: 17804,
        };
      }

      return $delegate;
    }
  }
})();
