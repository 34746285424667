(function () {
  angular.module('kmi.lms.train.user.courses').component('trainUserCourseRecord', {
    template: require('ajs/custom_modules/train/user/courses/course-record.html').default,
    controller: UserCourseRecordController,
    controllerAs: 'vm',
    bindings: {
      item: '=',
      columns: '=',
      statusSet: '=',
      highestResult: '=?',
      recordDataSource: '=?',
      showRemoveAction: '=?',
    },
  });

  /* @ngInject */
  function UserCourseRecordController(
    $scope,
    courseRegistrationStatus,
    currentUser,
    _,
    globalConfig,
    elms,
    assessmentResultsService,
    learningSeriesService,
    userCertificatesService,
  ) {
    let vm = this;
    vm.$onInit = onInit;

    vm.actionsOpen = false;
    vm.isNumber = angular.isNumber;
    vm.formatDigit = elms.formatDigit;
    vm.appSettings = globalConfig.settings;
    vm.globalCourseRegistrationSettings = globalConfig.courseRegistration || {};

    vm.getDataSource = courseRegistrationStatus.getDataSource;
    vm.downloadProofOfCompletion = learningSeriesService.downloadProofOfCompletion;
    vm.getStatusIdByUserCourse = courseRegistrationStatus.getStatusIdByUserCourse;
    vm.showCertificateStatus = showCertificateStatus;

    function onInit() {
      vm.status = setStatus(vm.item);
      vm.dataSource = vm.getDataSource(vm.item, vm.highestResult, vm.recordDataSource) || vm.item;

      vm.certificateStatusEnabled = vm.dataSource.hasCertificate;

      vm.item.statusName = courseRegistrationStatus.names[vm.status];
      if (
        vm.item.course &&
        vm.item.course.paymentApproval &&
        (vm.status === courseRegistrationStatus.approved || vm.status === courseRegistrationStatus.pending)
      ) {
        vm.item.statusName =
          courseRegistrationStatus.statusNamesOverride[vm.status] || courseRegistrationStatus.names[vm.status];
      }

      vm.item.statusTitle = courseRegistrationStatus.getTitle(vm.status);

      $scope.$watch('vm.statusSet', function () {
        if (vm.item.type === 'learning_series') {
          vm.item.visible = true;
          vm.disabled = !isVisible();
        } else {
          vm.item.visible = isVisible();
        }
      });
    }

    function isVisible() {
      var stack = [],
        node;
      stack.push(vm.item);

      while (stack.length > 0) {
        node = stack.pop();
        if (filterByStatus(node)) {
          return true;
        } else if (node.items && node.items.length) {
          for (var i = node.items.length - 1; i >= 0; i--) {
            stack.push(node.items[i]);
          }
        }
      }
      return false;
    }

    function filterByStatus(node) {
      var status = setStatus(node);
      return status && (!vm.statusSet || vm.statusSet.indexOf(status) >= 0);
    }

    function setStatus(node) {
      var status;
      if (node.type === 'externalRecord') {
        status = 5;
      } else if (!node.type || node.type === 'course') {
        status = vm.getStatusIdByUserCourse(node, vm.highestResult, vm.recordDataSource);
      }
      return status;
    }

    function showCertificateStatus(external) {
      userCertificatesService.printCertificate(vm.dataSource.id, external);
    }
  }
})();
